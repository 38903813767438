import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Font from "react-font";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/76961-weather-alert-notification.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";

export default class Secondment extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = this.props.screenHeight;
		let w = this.props.screenWidth > 1000 ? 1000 : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					minHeight: h,
					// height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
					paddingTop: "5%",
					// flex: 1,
				}}>
				<View
					style={{
						position: "absolute",
						bottom: 0,
						left: 0,
					}}>
					<Image
						source={require("./assets/dots.png")}
						style={{
							width: 500,
							height: 500,
							resizeMode: "contain",
						}}
					/>
				</View>
				<View
					style={{
						position: "absolute",
						top: 0,
						right: 0,
					}}>
					<Image
						source={require("./assets/dots.png")}
						style={{
							width: 500,
							height: 500,
							resizeMode: "contain",
						}}
					/>
				</View>
				<View
					style={{
						position: "absolute",
						bottom: 60,
						right: 0,
					}}>
					<Image
						source={require("./assets/agreeMent.png")}
						style={{
							width: 200 * (368.94 / 255.42),
							height: 200,
							resizeMode: "contain",
						}}
					/>
				</View>
				<View
					style={{
						width: "100%",
						backgroundColor: "#1C2B46",
						height: 65,
						position: "absolute",
						bottom: 0,
						left: 0,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							width: "100%",
							flexDirection: "row",
							maxWidth: 1200,
							justifyContent: "space-between",
						}}>
						<Image
							source={require("./assets/services.png")}
							style={{
								width: 300,
								height: 35,
								resizeMode: "contain",
							}}
						/>
						<Image
							source={require("./assets/infod.png")}
							style={{
								width: 220,
								height: 35,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>

				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						paddingBottom: "5%",
					}}>
					<View
						style={{
							width: w,

							// height: h,
							height: "auto",
							maxWidth: 1200,
							padding: 20,

							flex: 1,
							zIndex: 1,
						}}>
						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								// height: h * 0.2,
								width: w,
								// marginBottom: "40%",
							}}>
							<View
								style={{
									flex: 1,
									// padding: 20,
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<View
									style={{
										flex: 1,
									}}>
									<Text
										style={{
											fontSize: 35,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={700}>
											REVLON
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 35,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={500}>
											Secondment - Johannesburg offices
										</Font>
									</Text>
								</View>
							</View>
						</View>

						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								// height: h / 2,
								width: w,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										flexDirection: "row",
									}}></View>
								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 25,
										flexDirection: "row",
									}}>
									<Font family='Montserrat' weight={300}>
										We propose to provide Revlon with a
										secondment to assist with their PR
										needs. The secondment will be provided
										by our agency and will be based at
										Revlon's Joburg premises. The secondment
										will be appointed on a fixed-term
										contract for a period of 4 months,
										starting from the 10th of January 2023.
										The secondment will be managed by the
										Revlon team and will cover Elizabeth
										Arden as well. This portion of the
										retainer accounts for 30% and covers the
										costs of the secondment's services.
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={500}>
										Scope of work:
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
									}}>
									<Font family='Montserrat' weight={300}>
										The secondment will be responsible for
										assisting the Revlon team with various
										PR tasks, including but not limited to:
									</Font>
								</Text>

								{[
									{
										title: "Media Monitoring: ",
										desc: "The secondment will be responsible for monitoring media outlets for any mentions of Revlon or Elizabeth Arden, and creating reports as required.",
									},
									{
										title: "Administrative Tasks:",
										desc: " The secondment will assist the team with administrative tasks such as scheduling meetings, sending emails, and updating databases.",
									},
									{
										title: "Event Coordination:",
										desc: "The secondment will assist with the coordination of events, including sourcing venues, coordinating guest lists, and managing RSVPs.",
									},
									{
										title: "Research:",
										desc: "The secondment will assist the team with research tasks such as gathering data on industry trends and competitor analysis.",
									},
									{
										title: "Other Duties: ",
										desc: "The secondment may be required to perform other duties as assigned by the Revlon team within the scope of PR and community management.",
									},
								].map((d, i) => (
									<Text
										style={{
											fontSize: 15,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 5,
											flexDirection: "row",
											marginBottom: 5,
										}}>
										<Font family='Montserrat' weight={500}>
											{d.title}
										</Font>
										<Text
											style={{
												fontSize: 14,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												{d.desc}
											</Font>
										</Text>
									</Text>
								))}

								<Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={500}>
										Hours and Response Times:
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={300}>
										Proposed costs include full-time
										community managers dedicated to Revlon
										and Elizabeth Arden, available for 16
										hours a day, this is limited to
										community management on the platforms
										(responding to queries and deleting
										relevant comments). The community
										manager will be available to the Revlon
										team for queries and updates during
										working hours between (08:30-17:00). We
										guarantee an average response time of 30
										minutes during office hours. After-hours
										community management will be charged at
										after-hours rates on a case-by-case
										basis.
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={500}>
										Resource Inclusion:
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 2,
										flexDirection: "row",
										marginTop: 2,
									}}>
									<Font family='Montserrat' weight={300}>
										Secondment
									</Font>
								</Text>
								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 2,
										flexDirection: "row",
										marginTop: 2,
									}}>
									<Font family='Montserrat' weight={300}>
										The contract for the secondment will be
										a standard fixed-term contract that
										allows for annual, sick, and family
										responsibility leave as per the basic
										conditions of employment. The contract
										will be shared with Revlon for their
										review and approval. Should the
										requirement change for either Revlon or
										Elizabeth Arden, a recosting will be
										necessary.
									</Font>
								</Text>

								<View
									style={{
										flexDirection: "row",
										// marginBottom: 25,
										marginTop: 20,
										alignItems: "center",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontSize: 19,
											color: APP_COLOURS.TEXTCOLOR,

											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={700}>
											REVLON:{" "}
										</Font>
										<Text
											style={{
												fontSize: 19,
												color: APP_COLOURS.TEXTCOLOR,

												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Secondment:
											</Font>
										</Text>
									</Text>

									<Text
										style={{
											fontSize: 22,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 25,
											flexDirection: "row",
											marginTop: 25,
										}}>
										<Font family='Montserrat' weight={700}>
											R 20,125
										</Font>
									</Text>
								</View>

								<View
									style={{
										flexDirection: "row",
										// marginBottom: 25,
										marginTop: 20,
										alignItems: "center",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontSize: 19,
											color: APP_COLOURS.TEXTCOLOR,

											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={700}>
											Elizabeth Arden:{" "}
										</Font>
										<Text
											style={{
												fontSize: 19,
												color: APP_COLOURS.TEXTCOLOR,

												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Secondment:
											</Font>
										</Text>
									</Text>

									<Text
										style={{
											fontSize: 22,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 25,
											flexDirection: "row",
											marginTop: 25,
										}}>
										<Font family='Montserrat' weight={700}>
											R 8,625
										</Font>
									</Text>
								</View>

								<View
									style={{
										width: "100%",
										borderWidth: 0.5,
										borderColor: APP_COLOURS.TEXTCOLOR,
									}}
								/>
								<View
									style={{
										flexDirection: "row",
										// marginBottom: 25,
										marginTop: 20,
										alignItems: "center",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontSize: 19,
											color: APP_COLOURS.TEXTCOLOR,

											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={500}>
											Total:
										</Font>
									</Text>

									<Text
										style={{
											fontSize: 22,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 25,
											flexDirection: "row",
											marginTop: 25,
										}}>
										<Font family='Montserrat' weight={500}>
											R 28,750
										</Font>
									</Text>
								</View>
							</View>
						</View>

						<Text
							style={{
								fontSize: 15,
								color: APP_COLOURS.TEXTCOLOR,
								marginBottom: 2,
								flexDirection: "row",
								marginTop: 2,
							}}>
							<Font family='Montserrat' weight={300}>
								Our secondment will provide valuable assistance
								to the team, allowing them to focus on their
								core responsibilities. We are confident that our
								services will exceed your expectations and we
								look forward to the opportunity to work with
								you.
							</Font>
						</Text>

						<Text
							style={{
								fontSize: 13,
								color: APP_COLOURS.TEXTCOLOR,
								marginBottom: 2,
								flexDirection: "row",
								marginTop: 70,
							}}>
							<Font family='Montserrat' weight={300}>
								* All quoted amounts are monthly recurring and
								exclusive of VAT unless stated otherwise.
							</Font>
						</Text>
					</View>
				</View>
			</div>
		);
	}
}
