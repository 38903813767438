import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/91239-customer.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page7 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		let mw = 1200;
		let h = this.props.screenHeight;
		let w = this.props.screenWidth > mw ? mw : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		let fontS = {
			fontSize: 20,
			color: APP_COLOURS.TEXTCOLOR,
			fontFamily: "Montserrat",
			textAlign: "justify",
			fontWeight: "300",
		};

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
					// paddingBottom: "10%",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							width: w,
							// height: h,
							height: "auto",
							padding: 20,
							// paddingTop: "10%",
							zIndex: 1,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Fade>
							<div
							// className={
							// 	this.state.inView ? `reveal` : `fadeOut`
							// }
							>
								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h * 0.2,
										width: window.innerWidth * 0.8,
										maxWidth: mw,
									}}>
									<View
										style={{
											flex: 1,
											// padding: 20,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													fontSize: 50,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={600}>
													Telling Our Story
												</Font>
											</Text>
											<Text
												style={{
													fontSize: 22,

													marginBottom: 5,
													flexDirection: "row",
													opacity: 0.4,
													color: "#000",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													Creating a world of luxury
													through compelling content.
												</Font>
											</Text>
										</View>
									</View>
									<View
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
										}}>
										<Lottie
											options={defaultOptions}
											height={h * 0.38}
											width={h * 0.45}
										/>
									</View>
								</View>

								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h / 2,
										width: window.innerWidth * 0.8,
										maxWidth: mw,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={[
												{
													fontSize: 22,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												},
												fontS,
											]}>
											Our content strategy is centered
											around showcasing the unique
											qualities and luxurious experiences
											that define Revlon & Elizabeth
											Arden. By creating captivating,
											high-quality content across various
											formats, we aim to immerse our
											audience in the essence of our
											brands, building strong emotional
											connections and driving engagement.
										</Text>

										<Text
											style={{
												fontSize: 19,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												marginTop: 15,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Product Showcases:
											</Font>
										</Text>

										<Text
											style={[
												{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												},
												fontS,
											]}>
											Feature our latest beauty products,
											limited edition releases, and
											signature collections through
											visually stunning imagery and
											videos. Share the story behind each
											product, highlighting the
											ingredients, technology, and
											benefits that set them apart.
											Utilize video tutorials, product
											demonstrations, and virtual try-on
											experiences to help our audience
											discover and explore our products.
										</Text>

										<Text
											style={{
												fontSize: 19,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												marginTop: 15,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Brand Heritage Stories:
											</Font>
										</Text>

										<Text
											style={[
												{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												},
												fontS,
											]}>
											Dive into the rich history and
											legacy of Revlon & Elizabeth Arden,
											sharing the stories of our founders,
											iconic products, and milestones.
											Create a series of video interviews
											or written profiles, focusing on the
											people who have shaped the brands
											and their vision for the future.
											Highlight the philanthropic
											initiatives and social impact
											associated with Revlon & Elizabeth
											Arden.
										</Text>

										<Text
											style={{
												fontSize: 19,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												marginTop: 15,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Seasonal Campaigns:
											</Font>
										</Text>

										<Text
											style={[
												{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												},
												fontS,
											]}>
											Develop visually striking campaigns
											that align with seasonal trends and
											key calendar events, such as
											holidays, fashion weeks, or product
											launches. Utilize a mix of
											photography, videos, and interactive
											content to create immersive and
											engaging experiences for our
											audience. Collaborate with
											influencers, celebrities, and beauty
											experts to amplify the reach and
											impact of our seasonal campaigns.
										</Text>

										<Text
											style={{
												fontSize: 19,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												marginTop: 15,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Beauty Inspirations & Tutorials:
											</Font>
										</Text>

										<Text
											style={[
												{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												},
												fontS,
											]}>
											Beauty Inspirations & Tutorials:
											Share beauty tips, makeup tutorials,
											and skincare routines to educate and
											inspire our audience, positioning
											Revlon & Elizabeth Arden as trusted
											beauty authorities. Partner with
											beauty influencers and experts to
											create authentic content that
											resonates with our target audience.
											Host live-streamed masterclasses or
											workshops, offering our community
											exclusive access to industry
											insights and expertise.
										</Text>

										<Text
											style={{
												fontSize: 19,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												marginTop: 15,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Customer Stories:
											</Font>
										</Text>

										<Text
											style={[
												{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												},
												fontS,
											]}>
											Encourage user-generated content by
											inviting customers to share their
											beauty experiences with our
											products, such as makeup looks,
											skincare transformations, or
											personal stories. Feature
											exceptional customer stories in a
											dedicated section on our website or
											social media platforms. Create a
											branded hashtag to encourage
											customers to share their experiences
											and engage with Revlon & Elizabeth
											Arden.
										</Text>

										<Text
											style={{
												fontSize: 19,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												marginTop: 15,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Visuals and Content Samples:
											</Font>
										</Text>

										<Text
											style={[
												{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												},
												fontS,
											]}>
											Include high-quality images and
											video snippets showcasing the
											different content types mentioned
											above. Provide examples of product
											showcases, brand heritage stories,
											seasonal campaign visuals, beauty
											inspirations, and customer stories
											to illustrate the proposed content
											strategy.
										</Text>
									</View>
								</View>
							</div>
						</Fade>
					</View>

					{/* <PageFooter /> */}
				</View>
			</div>
		);
	}
}
