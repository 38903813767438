import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Font from "react-font";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/76961-weather-alert-notification.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";

import moment from "moment";
import ImageLoop from "./ImageLoop";
import LandingPage from "./LandingPage";

export default class ProposalPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inView: false,
			clientLogo: "",
			pages: [
				{
					body: [
						"",
						{
							type: "img",
							data: require("./assets/eaLogo.png"),
							width: 230,
							height: 116,
						},
						"",
					],
				},
				{
					body: [
						{
							type: "h1",
							text: "Skin Diagnostics tool with in-store QR Code.",
							subtitle: `Lead customers to our skindiagnostics site where they will be prompted to fill out a short questionaire. `,
						},
						{
							type: "img",
							data: require("./assets/skintoolsQR.png"),
							width: 181.79,
							height: 259,
						},
					],
				},
				{
					body: [
						{
							type: "h2",
							text: "Skin tool web application with QR code",
						},
					],
				},

				{
					body: [
						{
							type: "p",
							text: "We propose to develop a skin diagnostics web application for Elizabeth Arden. The proposed platform will allow customers to scan a QR code to access the web application, where they will be prompted to fill out a short multiple-choice questionnaire. Based on their answers, the web application will advise them which Elizabeth Arden product to use. They will then see options on where to purchase the product depending on the retailer they scanned the QR code from, such as Foschini, Superbalist, Takealot, and others.",
						},
					],
				},

				{
					body: [
						{
							type: "p",
							text: `A customer would scan the QR code to access the skin diagnostics web application because it offers them an easy and convenient way to get personalized recommendations for skincare products based on their specific skin concerns and needs. By filling out the short multiple-choice questionnaire, the app can gather information about the user's skin type, concerns, and preferences, and then provide recommendations for the best Elizabeth Arden products to address those issues.

Additionally, the app also provides users with options on where to purchase the recommended products depending on the retailer they scanned the QR code from, such as Foschini, Superbalist, Takealot, and others. This means that users can easily find and purchase the products they need without having to do extensive research or search through multiple websites or stores.
                     
Overall, scanning the QR code and using the web application can save customers time and effort in finding the right skincare products, while also providing them with personalized recommendations and convenient purchasing options.

Once a customer has used the skin diagnostics web application to find the right Elizabeth Arden products for their skin concerns and needs, the next step would be to download the free Elizabeth Arden skincare app. This app would allow users to track their skincare progress and participate in a 7-day skin transformation challenge.

The skincare app would enable users to track their daily skincare routine and monitor the improvements in their skin over time. This could include tracking factors such as hydration, texture, and radiance. Users could also receive personalized skincare tips and advice based on their progress and needs.

Additionally, the app could offer a 7-day skin transformation challenge, where users follow a prescribed skincare routine using the recommended Elizabeth Arden products for seven days to achieve a visible improvement in their skin. This could include daily reminders to use the products, as well as helpful tips and guidance on how to maximize the benefits of the products.

By offering the skincare app and 7-day transformation challenge, Elizabeth Arden can provide an additional layer of value to their customers beyond just product recommendations. The app can help to build brand loyalty and engagement by providing users with a comprehensive and personalized skincare experience.`,
						},
					],
				},
				{
					body: [
						{
							type: "h2",
							text: "Customer retention",
						},
					],
				},

				{
					body: [
						{
							type: "p",
							text: `
Once a customer has used the skin diagnostics web application to find the right Elizabeth Arden products for their skin concerns and needs, the next step would be to download the free Elizabeth Arden skincare app. This app would allow users to track their skincare progress and participate in a 7-day skin transformation challenge.

The skincare app would enable users to track their daily skincare routine and monitor the improvements in their skin over time. This could include tracking factors such as hydration, texture, and radiance. Users could also receive personalized skincare tips and advice based on their progress and needs.

Additionally, the app could offer a 7-day skin transformation challenge, where users follow a prescribed skincare routine using the recommended Elizabeth Arden products for seven days to achieve a visible improvement in their skin. This could include daily reminders to use the products, as well as helpful tips and guidance on how to maximize the benefits of the products.

By offering the skincare app and 7-day transformation challenge, Elizabeth Arden can provide an additional layer of value to their customers beyond just product recommendations. The app can help to build brand loyalty and engagement by providing users with a comprehensive and personalized skincare experience.`,
						},
					],
				},

				{
					body: [
						{
							type: "p",
							text: "The web application will be developed using the latest web technologies to ensure compatibility with a wide range of devices and browsers. The backend services will be hosted on a secure cloud host to ensure data privacy and security. The web application will also comply with POPI and GDPR requirements for handling customer data.",
						},
					],
				},
				// {
				// 	body: [
				// 		{
				// 			type: "li",
				// 			text: "Skin Diagnostics tool with in-store QR Code.",
				// 			subtitle: `Lead customers to our skindiagnostics site where they will be prompted to fill out a short questionaire. `,
				// 			items: [
				// 				{
				// 					text: "Skin Diagnostics tool with in-store QR Code.",
				// 					subtitle: `Lead customers to our skindiagnostics site where they will be prompted to fill out a short questionaire. `,
				// 				},
				// 			],
				// 		},
				// 	],
				// },
				{
					body: [
						{
							type: "h2",
							text: "Tracking Analytics:",
						},
					],
				},
				{
					body: [
						{
							type: "p",
							text: `We will incorporate tracking analytics in the skin diagnostics web application to track QR codes created for different malls and retailers, depending on the unique QR code. All URL tracking metrics will be recoreded by TRAQ web services and accessible vis the dashboard.traq.app dashboard. This will allow us to provide a reporting dashboard for Elizabeth Arden to get reports on usage. By tracking the usage of different QR codes, we can determine which retailers are generating the most traffic and which malls are performing the best.
                     `,
						},
					],
				},
				{
					body: [
						{
							type: "h2",
							text: "Application flows:",
						},
					],
				},
				{
					body: [
						{
							type: "p",
							text: `Urls to be created with respective QR codes:
                     URLS: 
                     elizabetharden.###.co.za
                     elizabetharden-edgars.s###.co.za
                     elizabetharden-foschini.s###.co.za
                     elizabetharden-superbalist.###co.za
                     elizabetharden-takealot.s###.co.za
                     elizabetharden-woolworths.###co.za
                     elizabetharden-dischem.###co.za
                     elizabetharden-clicks.###co.za
                     `,
						},
					],
				},
				// 				{
				// 					body: [
				// 						{
				// 							type: "h2",
				// 							text: "Locations: ",
				// 						},
				// 					],
				// 				},
				// 				{
				// 					body: [
				// 						{
				// 							type: "p",
				// 							text: `10 Malls in Gauteng, Cape Town and Durban respectively. Additional malls can be added on request

				// QR codes can be strategically placed in order to capture analytics from various malls and retailers.
				//                      `,
				// 						},
				// 					],
				// 				},
				{
					body: [
						{
							type: "h2",
							text: "Suggestions for the Web Application: ",
						},
					],
				},
				{
					body: [
						{
							type: "p",
							text: "Incorporate a feature that allows customers to see the benefits of the recommended product and how it will improve their skin. Integrate social media sharing options to allow customers to share their results with their friends and followers. Offer exclusive discounts and promotions to customers who purchase the recommended product through the web application. Include a feedback form to gather customer feedback and improve the web application.",
						},
					],
				},

				{
					body: [
						{
							type: "slide",
							items: [
								{
									image: require("./assets/0db9e425-520f-45e4-9327-a4972472f786.JPG"),
									title: "",
									description: "Screenshot",
								},
								{
									image: require("./assets/b9b964da-7435-4274-8125-4b778e063f21.JPG"),
									title: "",
									description: "Screenshot",
								},
								{
									image: require("./assets/a27c9b77-95b8-448f-b837-2d6216b5bae4.JPG"),
									title: "",
									description: "Screenshot",
								},
								{
									image: require("./assets/78258cf9-0f02-4fb4-9267-2f5812622f89.JPG"),
									title: "",
									description: "Screenshot",
								},
							],
						},
					],
				},
			],

			costEstimate: {
				clientDetails: "Elizabeth Arden",
				title: "Skin tools Web App",
				description: `To accurately determine the cost of the application, we need to create a flow diagram and wireframe to design the platform. This will enable us to determine the development and time required to complete the project, as well as the scale and detail of backend services. We must take into account important factors such as the amount of data to be streamed, the number of users the app will have, and the data being stored and distributed.

            Some aspects of the application involve development-only costs, while others require minimal development yet carry a monthly running cost. In addition, we have drafted a mock-up of the app to provide a visual representation of the proposed user interface. Of course, the user interface will be designed to reflect the brand's product colors.`,

				lines: [
					{
						title: "Setup costs (Once off)",
						description: `Design cost, 
URL setup,Dashboard setup,
Setup & file hosting,
Questionnaire logic`,
						price: "R 11,500",
					},
					{
						title: "Monthly Operational Costs",
						description: `Hosting x 10 trackable QR codes, 
Cloud Services,
Security Services,
Tech Support SLA`,
						price: "R 6,820",
					},
					{
						title: "Additional",
						description: `Per additional trackable QR code: `,
						price: "R 682",
					},
				],
				additional: `Monthly thresholds are applicable and service costs are based on a mimimum level of useage. 

- Hosting (10,000 interactions per month) 
- Cloud Storage: SSD storage size: 80GB
- Cloud backups & snapshots
- A system-level backup is taken once a month, and each backup is retained for 4 weeks.

Communications thresholds:
- SMS's (not included)
- Email's (not included)`,
				terms: ``,

				expiry: moment("10/05/2023", "DD/MM/YYYY").format(
					"DD MMM YYYY",
				),
			},
		};
	}

	pages = (d, h1, h2, pp) => {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = window.innerHeight;
		let w = window.innerWidth > 1000 ? 1000 : window.innerWidth;

		let imgWid = w / d.body.length > 400 ? 400 : w / d.body.length;

		return (
			<div
				style={{
					width: window.innerWidth,
					height: "auto",
					justifyContent: "center",
					alignItems: "center",
					zIndex: 5,
					// padding: "5%",
				}}>
				<View
					style={{
						height: "auto",
						width: "100%",
						zIndex: 1,
						maxWidth: 1000,
						paddingLeft: "5%",
						paddingRight: "5%",
						marginLeft: "auto",
						marginRight: "auto",
						marginBottom: pp,
					}}>
					<View
						style={{
							flexDirection: "row",

							width: "100%",
							justifyContent: "space-between",
							// marginBottom: 10,
						}}>
						{d.body.map((dd) =>
							dd.type === "img" ? (
								<Image
									source={dd.data}
									style={{
										width: imgWid * (dd.width / dd.height),
										height: imgWid,
										maxWidth: dd.width,
										maxHeight: dd.height,
										resizeMode: "contain",
										marginBottom: h1,
									}}
								/>
							) : dd.type === "h1" ? (
								<View
									style={{
										flex: 1,
										// marginTop: h1,
										// marginBottom: h1,
									}}>
									<Text
										style={{
											fontSize: h1,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={600}>
											{dd.text}
										</Font>
									</Text>
									<Text
										style={{
											fontSize: h2,
											marginBottom: h2,
											flexDirection: "row",
											opacity: 0.4,
											color: "#000",
										}}>
										<Font family='Montserrat' weight={500}>
											{dd.subtitle}
										</Font>
									</Text>
								</View>
							) : dd.type === "h2" ? (
								<View
									style={{
										flex: 1,
										// marginTop: h2,
										// marginBottom: h2,
									}}>
									<Text
										style={{
											fontSize: h2,
											color: APP_COLOURS.TEXTCOLOR,

											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={500}>
											{dd.text}
										</Font>
									</Text>
								</View>
							) : dd.type === "p" ? (
								<Text
									style={{
										flex: 1,
										fontSize: pp,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: pp,
										fontFamily: "Montserrat",
										textAlign: "justify",
										fontWeight: "300",
									}}>
									{/* <Font
													family='Montserrat'
													weight={300}> */}
									{dd.text}
									{/* </Font> */}
								</Text>
							) : dd.type === "li" ? (
								<View
									style={{
										flex: 1,
									}}>
									<Text
										style={{
											fontSize: pp,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 5,
											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={500}>
											{dd.text}
										</Font>
									</Text>
									<Text
										style={{
											fontSize: pp,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 15,
											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={300}>
											{dd.subtitle}
										</Font>
									</Text>
									{dd.items.map((ddd) => (
										<>
											<Text
												style={{
													fontSize: pp,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
													// paddingLeft: 15,
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													- {ddd.text}
												</Font>
											</Text>
											<Text
												style={{
													fontSize: pp,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: pp,
													flexDirection: "row",
													paddingLeft: pp,
												}}>
												<Font
													family='Montserrat'
													weight={300}>
													{ddd.subtitle}
												</Font>
											</Text>
										</>
									))}
								</View>
							) : dd.type === "slide" ? (
								<View
									style={{
										flex: 1,
									}}>
									<ImageLoop
										steps={dd.items}
										stepTitle={(title) =>
											this.setState({
												stepTitle: title,
											})
										}
									/>
								</View>
							) : (
								<View
									style={{
										flex: 1,
									}}
								/>
							),
						)}
					</View>
				</View>
			</div>
		);
	};

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = window.innerHeight;
		let w = window.innerWidth > 1000 ? 1000 : window.innerWidth;

		let ce = this.state.costEstimate;

		let fs = window.innerWidth * 0.07;
		let maxFont = 35;
		let h1 = fs < maxFont ? fs : maxFont;
		let h2 = h1 * 0.7;
		let pp = h1 * 0.6;

		return (
			<View
				style={{
					overflow: "hidden",
					// backgroundColor: "#EDF1F4",
					width: window.innerWidth,
				}}>
				<LandingPage {...this.state} h1={h1} h2={h2} pp={pp} />
				<View
					style={{
						width: window.innerWidth,
						backgroundColor: "#FFF",
						// backgroundColor: "red",
						borderRadius: 30,
						justifyContent: "center",
						alignItems: "center",
						height: window.innerHeight * 0.1,
						top: -window.innerHeight * 0.05,
					}}
				/>
				{this.state.pages.map((d) => this.pages(d, h1, h2, pp))}
				<div
					style={{
						// flex: 1,
						width: window.innerWidth,
						// height: h,
						// height: "auto",
						// backgroundColor: "#1F2229",
						// backgroundColor: "#FFF",
						// overflow: "hidden",
						justifyContent: "center",
						alignItems: "center",
						paddingTop: "5%",
						// flex: 1,
					}}>
					{/* <View
						style={{
							position: "absolute",
							bottom: 0,
							left: 0,
						}}>
						<Image
							source={require("./assets/dots.png")}
							style={{
								width: 500,
								height: 500,
								resizeMode: "contain",
							}}
						/>
					</View> */}
					<View
						style={{
							position: "absolute",
							top: window.innerHeight * 0.8,
							right: 0,
							zIndex: 1,
						}}>
						<Image
							source={require("./assets/dots.png")}
							style={{
								width: 500,
								height: 500,
								resizeMode: "contain",
							}}
						/>
					</View>
					<View
						style={{
							position: "absolute",
							bottom: 84,
							right: 0,
						}}>
						<Image
							source={require("./assets/agreeMent.png")}
							style={{
								width: 200 * (368.94 / 255.42),
								height: 200,
								resizeMode: "contain",
							}}
						/>
					</View>
					<View
						style={{
							width: "100%",
							backgroundColor: "#1C2B46",
							height: 85,
							position: "absolute",
							bottom: 0,
							left: 0,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								width: "100%",
								flexDirection: "row",
								maxWidth: 1000,
								justifyContent: "space-between",
								paddingLeft: 10,
								paddingRight: 10,
							}}>
							<Image
								source={require("./assets/services.png")}
								style={{
									width: 250,
									maxWidth: window.innerWidth * 0.4,
									height: 35,
									resizeMode: "contain",
								}}
							/>
							<Image
								source={require("./assets/infod.png")}
								style={{
									width: 220,
									maxWidth: window.innerWidth * 0.4,
									height: 35,
									resizeMode: "contain",
								}}
							/>
						</View>
					</View>

					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							paddingBottom: 350,
						}}>
						<View
							style={{
								width: "100%",

								// height: h,
								height: "auto",
								maxWidth: 1000,

								flex: 1,
								zIndex: 1,
								padding: "5%",
							}}>
							<View
								style={{
									// flex: 1,
									flexDirection: "row",
									// height: h * 0.2,
									width: "100%",
									// marginBottom: "40%",
								}}>
								<View
									style={{
										flex: 1,
										// padding: 20,
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={{
												fontSize: h1,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											<Font family='Poppins' weight={500}>
												{ce.clientDetails}
											</Font>
										</Text>
										<Text
											style={{
												fontSize: h1,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											<Font family='Poppins' weight={700}>
												{ce.title}
											</Font>
										</Text>
									</View>
								</View>
							</View>

							<View
								style={{
									width: "100%",
								}}>
								{ce.lines.map((dd) => (
									<View
										style={{
											marginTop: 20,
											marginBottom: 20,
											paddingBottom: 10,
											borderBottomWidth: 0.5,
											borderColor: APP_COLOURS.TEXTCOLOR,
										}}>
										<View
											style={{
												flexDirection: "row",
												justifyContent: "space-between",
											}}>
											<Text
												style={{
													fontSize: pp,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Montserrat'
													weight={700}>
													{dd.title}
												</Font>
											</Text>
											<Text
												style={{
													fontSize: pp,
													color: APP_COLOURS.TEXTCOLOR,

													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={700}>
													{dd.price}
												</Font>
											</Text>
										</View>

										<Text
											style={{
												fontSize: pp,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												{dd.description}
											</Font>
										</Text>
									</View>
								))}
							</View>

							{/* <View
								style={{
									width: "100%",
									borderWidth: 0.5,
									borderColor: APP_COLOURS.TEXTCOLOR,
								}}
							/> */}

							<Text
								style={{
									fontSize: pp,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
									marginTop: 2,
								}}>
								<Font family='Montserrat' weight={300}>
									{ce.additional}
								</Font>
							</Text>

							<Text
								style={{
									fontSize: pp,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
									marginTop: 2,
								}}>
								<Font family='Montserrat' weight={300}>
									{ce.terms}
								</Font>
							</Text>

							<Text
								style={{
									fontSize: pp - 2,
									color: moment().isAfter(moment(ce.expiry))
										? APP_COLOURS.RED
										: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
									marginTop: 50,
								}}>
								<Font family='Montserrat' weight={300}>
									* This quotation is valid until {ce.expiry}
									{moment().isAfter(moment(ce.expiry))
										? ", and has expired"
										: `, expires ${moment(
												ce.expiry,
										  ).fromNow()}`}
								</Font>
							</Text>

							<Text
								style={{
									fontSize: pp - 2,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									* All quoted amounts are based in USD/ZAR
									exchange rate at the time of quoting and may
									be subject to change
								</Font>
							</Text>

							<Text
								style={{
									fontSize: pp - 2,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									* All quoted amounts are monthly recurring
									and exclusive of VAT unless stated
									otherwise.
								</Font>
							</Text>
						</View>
					</View>
				</div>
			</View>
		);
	}
}
