import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import Lottie from "react-lottie";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "./APP_VARS";

import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

import * as animationData from "./assets/28608-make-payment.json";

export default class Page2 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		let mw = 1200;
		let h = this.props.screenHeight;
		let w = this.props.screenWidth > mw ? mw : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		let fontS = {
			fontSize: 20,
			color: APP_COLOURS.TEXTCOLOR,
			fontFamily: "Montserrat",
			textAlign: "justify",
			fontWeight: "300",
		};

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// backgroundColor: "#EDF1F4",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
					// paddingBottom: "10%",
					// padding: "10%",
				}}>
				<View
					style={{
						// width: w,
						// height: h,
						height: "auto",
						padding: 20,
						// paddingTop: "10%",
						zIndex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					{/* <div
							className={
								this.state.inView ? `reveal` : `fadeOut`
							}> */}
					<Fade>
						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								// height: h * 0.33,
								// width: w,
								marginBottom: "10%",
								// width: window.innerWidth * 0.8,

								width: window.innerWidth * 0.8,
								maxWidth: mw,
							}}>
							<View
								style={{
									flex: 1,

									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<View
									style={{
										flex: 1,
									}}>
									<Text
										style={{
											fontSize: 50,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={600}>
											The Luxury Retail Landscape
										</Font>
									</Text>

									<Text
										style={{
											fontSize: 22,
											color: "#000",
											opacity: 0.6,
											marginTop: 10,
										}}>
										<Font family='Poppins' weight={400}>
											The ever-changing market of high-end
											products and experiences catering to
											discerning consumers.
										</Font>
									</Text>
								</View>
							</View>
							<View
								style={{
									flex: 1,
									padding: 20,
								}}>
								{/* <Image
										source={require("./assets/pluginContent.png")}
										style={{
											width: "100%",
											height: h * 0.33,
											resizeMode: "contain",
											right: "-10%",
											bottom: 0,
										}}
									/> */}
								<Lottie
									options={defaultOptions}
									height={h * 0.3}
									width={h * 0.45}
								/>
							</View>
						</View>
					</Fade>

					<Fade>
						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								// height: h / 2,
								height: "auto",
								// width: w,

								width: window.innerWidth * 0.8,
								maxWidth: mw,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<Text
									style={[
										fontS,
										{
											marginVertical: 25,
											flexDirection: "row",
											fontSize: 20,
										},
									]}>
									The luxury beauty and cosmetics market is
									experiencing significant growth, driven by
									evolving consumer preferences, innovative
									product offerings, and a shift towards
									premiumization. As two iconic brands, Revlon
									and Elizabeth Arden have the potential to
									capitalize on this growth and solidify their
									positions within the luxury retail
									landscape.
								</Text>

								<Text
									style={[
										fontS,
										{
											color: APP_COLOURS.TEXTCOLOR,
											marginVertical: 25,
											flexDirection: "row",
										},
									]}>
									Revlon, known for its cutting-edge makeup
									products and innovative marketing campaigns,
									appeals to a diverse demographic seeking
									high-quality beauty solutions. Meanwhile,
									Elizabeth Arden, with its rich history of
									luxury skincare and fragrance offerings,
									caters to a more mature and sophisticated
									audience. Together, these brands cover a
									wide range of the luxury beauty market,
									offering an opportunity for synergy and
									collaboration.
								</Text>

								<Text
									style={[
										fontS,
										{
											marginVertical: 25,
											flexDirection: "row",
										},
									]}>
									<Font family='Montserrat' weight={300}>
										Key challenges and opportunities in the
										luxury retail landscape for Revlon and
										Elizabeth Arden include:
									</Font>
								</Text>

								{[
									{
										title: "Consumer preferences:",
										desc: "Understanding and catering to the ever-changing preferences of luxury beauty consumers, including a growing emphasis on clean beauty, sustainability, and personalized experiences.",
									},

									{
										title: "Competitive landscape:",
										desc: "Navigating a highly competitive market, with the rise of niche and indie brands, as well as increasing competition from established luxury beauty brands.",
									},
									{
										title: "Omnichannel retail:",
										desc: "Embracing the shift towards online shopping and developing seamless omnichannel experiences that cater to consumers' desire for convenience and personalized interactions.",
									},
									{
										title: "Global expansion:",
										desc: "Identifying and capitalizing on emerging markets, while maintaining a strong presence in established luxury retail markets.",
									},
									{
										title: "Brand differentiation:",
										desc: "Clearly differentiating Revlon and Elizabeth Arden from competitors by emphasizing their unique selling propositions, heritage, and commitment to innovation.",
									},
								].map((d, i) => (
									<Text
										style={[
											fontS,
											{
												marginBottom: 5,
												flexDirection: "row",
												marginBottom: 15,
											},
										]}>
										<Text
											style={{
												fontWeight: "500",
											}}>
											{d.title}{" "}
										</Text>
										<Text>{d.desc}</Text>
									</Text>
								))}

								<Text
									style={[
										fontS,
										{
											fontSize: 20,
											color: APP_COLOURS.TEXTCOLOR,
											marginVertical: 25,
											flexDirection: "row",
										},
									]}>
									By addressing these challenges and
									capitalizing on the opportunities in the
									luxury retail landscape, Revlon and
									Elizabeth Arden can strengthen their
									positions as leading beauty brands and
									create lasting connections with their
									discerning customer base.
								</Text>
							</View>
						</View>
					</Fade>
					{/* </div> */}
				</View>
			</div>
		);
	}
}
