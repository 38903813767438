import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Font from "react-font";
import { Fade } from "react-reveal";
import moment from "moment";
import { APP_COLOURS } from "./APP_VARS";
import logo_tetrice from "./assets/logo_tetrice.png";
import "./resizeScale.scss";
import "./hoverEffect.scss";

import bg1 from "./assets/bg_tech.png";

export default class PageFooter extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let mw = 1200;

		let contain =
			this.props.screenWidth * 0.9 > this.props.screenHeight * 0.9
				? this.props.screenHeight * 0.89
				: this.props.screenWidth * 0.9;

		let maxContain = contain > 300 ? 300 : contain;

		let ar = 636 / 545;

		let h = this.props.screenHeight;
		let w = this.props.screenWidth;

		return (
			<View
				style={{
					// justifyContent: "center",
					paddingTop: "5%",
					width: window.innerWidth,
					backgroundColor: "#171A20",
					alignItems: "center",
					padding: 30,
					minHeight: window.innerHeight * 0.5,
				}}>
				<View style={{ width: "90%", maxWidth: mw }}>
					<View
						style={{
							flexDirection: "row",
							width: "100%",
							justifyContent: "space-between",
							alignItems: "center",
						}}>
						<Image
							source={logo_tetrice}
							style={{
								width: 180,
								height: 100,
								resizeMode: "contain",
							}}
						/>
						<Image
							source={require("./assets/services.png")}
							style={{
								width: 300,
								height: 120,
								resizeMode: "contain",
								// position: "absolute",
								// left: -10,
							}}
						/>
						<Image
							source={require("./assets/infod.png")}
							style={{
								width: 240,
								height: 120,
								resizeMode: "contain",
								// position: "absolute",
								// left: -10,
							}}
						/>
					</View>
					{/* <View
						style={{
							flex: 1,
							paddingRight: "10%",
						}}>
						<Fade>
							<View
								style={
									{
										// flex: 1,
									}
								}>
								<Text
									style={{
										fontSize: 14,
										color: "#FFF",
									}}>
									<Font family='Poppins' weight={800}>
										Consulting
									</Font>
								</Text>
								<Text
									style={{
										fontSize: 14,
										color: "#FFF",
									}}>
									<Font family='Poppins' weight={800}>
										Software as a Service
									</Font>
								</Text>
							</View>
						</Fade>
					</View> */}

					<View
						style={{
							justifyContent: "space-between",
							marginVertical: 30,
						}}>
						<View>
							<Text
								style={{
									fontSize: 14,
									color: "#FFF",
									paddingBottom: 5,
								}}>
								<Font family='Montserrat' weight={400}>
									Contact:
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 14,
									color: "#FFF",
								}}>
								<Font family='Montserrat' weight={300}>
									info@tetrice.co.za
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 14,
									color: "#FFF",
								}}>
								<Font family='Montserrat' weight={300}>
									081 250 2054
								</Font>
							</Text>
						</View>
						<View
							style={{
								marginVertical: 30,
							}}>
							<Text
								style={{
									fontSize: 14,
									color: "#FFF",
									paddingBottom: 5,
								}}>
								<Font family='Montserrat' weight={400}>
									Locations:
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 14,
									color: "#FFF",
									paddingBottom: 5,
								}}>
								<Font family='Montserrat' weight={300}>
									159 Rivonia Road, Morningside, Sandton
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 14,
									color: "#FFF",
									paddingBottom: 5,
								}}>
								<Font family='Montserrat' weight={300}>
									304 Pierneef, William Nicole Drive, Fourways
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 14,
									color: "#FFF",
									paddingBottom: 5,
								}}>
								<Font family='Montserrat' weight={300}>
									161 Trump Street, Pomona, Kempton Park
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 14,
									color: "#FFF",
									paddingBottom: 5,
								}}>
								<Font family='Montserrat' weight={300}>
									5600 Tennyson Parkway,Plano Legacy West, TX
									75024
								</Font>
							</Text>
						</View>
					</View>

					{/* <View
						style={{
							flex: 1,
						}}>
						<Fade cascade left>
							<Text
								style={{
									fontSize: 14,
									color: "#FFF",
								}}>
								<Font family='Poppins' weight={800}>
									PR & Community Management
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 14,
									color: "#FFF",
								}}>
								<Font family='Poppins' weight={400}>
									Proposal Prepared for Revlon & Elizabeth
									Arden
								</Font>
							</Text>
						</Fade>
					</View> */}

					{/* <View>
						<Image
							source={bg1}
							style={{
								// width: maxContain * ar,
								// height: maxContain,

								width: 500,
								height: 500,
								// maxWidth: "45%",
								resizeMode: "contain",
								position: "absolute",
								right: 0,
								top: 0,
							}}
						/>
					</View> */}
				</View>

				{/* <View
					style={{
						width: 200,
					}}>
					<Text
						style={{
							fontSize: 19,
							color: "#121419",
							padding: 0,
							margin: 0,
						}}>
						<Font family='Poppins' weight={800}>
							tetrice
						</Font>
					</Text>
					<View
						style={{
							flexDirection: "row",
							padding: 0,
							margin: 0,
						}}>
						<Text
							style={{
								fontSize: 10,
								color: "#121419",
								flexDirection: "row",
								fontWeight: "600",
								padding: 0,
								margin: 0,
							}}>
							Consulting
							<Text style={{ fontWeight: "300" }}>Services</Text>
						</Text>
					</View>
				</View> */}
			</View>
		);
	}
}
