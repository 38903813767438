import moment from "moment";

export const SOWData = {
	projectType: "PR Event",
	projectTitle: "Oh Ship - White Tea skin solutions event ",
	pages: [
		{
			body: [
				{
					type: "h1",
					text: "Executive Summary:",
				},
			],
		},
		{
			body: [
				{
					type: "h2",
					text: "Bringing Elizabeth Arden onboard the SPLENDIDA with OH SHIP'S infamous White Party",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "The White Party has been a staple in the OH Ship experience that has become a well anticipated and highly covered on social media.",
				},
			],
		},
		{
			body: [
				{
					type: "img",
					data: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2001.55.07.webp?alt=media&token=b76e4c05-bc94-42ba-b3d1-8cea9ef5dcd7",
					width: 784,
					height: 874,
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Event description:",
				},
			],
		},
		{
			body: [
				{
					type: "h2",
					text: "The White Party",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "The White Party has been a staple in the OH Ship experience that has become a well anticipated and highly covered on social media.",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "The opportunity to sponsor the white party brings a unique captive audience of just over three thousand people that can live the Elizabeth Arden experience on board the MSC Splendida and feel the luxury of the White Tea Skin Solutions range.",
				},
			],
		},
		{
			body: [
				{
					type: "img",
					data: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2Fevent.webp?alt=media&token=6c8dcd64-21a8-4ac3-8a0b-8d39ddb8a234",
					width: 623.54,
					height: 534,
				},
			],
		},

		{
			body: [
				{
					type: "h1",
					text: "VISION",
				},
			],
		},
		{
			body: [
				{
					type: "h2",
					text: "all about the luxury",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Make the entire day all about the luxury experience you get with the White Tea Skin Solutions. The boat is an excellent way to make the guests on board feel special while showcasing the product.",
				},
				{
					type: "img",
					data: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2002.20.16.webp?alt=media&token=9f6de243-5f07-4d6c-a141-afebd30bdb40",
					width: 477,
					height: 498,
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "By using promotional gifts, photo-ops and a special offer of a coupon or free gift with purchase we can drive the testing of the range in the South African context.",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "This event will drive exposure of the brand and the new launch through Social Media by using hashtags, reposts on social and future promotional videos using content from this event. We will be able to bring Elizabeth Arden to an audience on board that can further share the brand on their own social media using the curated environment on board.",
				},
			],
		},

		{
			body: [
				{
					type: "img",
					data: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2010.03.46.png?alt=media&token=2070758d-2712-4ca4-b959-b0c1ee3e499f",
					width: 930.52,
					height: 522,
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Promotion and Marketing Strategy:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Pre-event marketing activities, at-event promotions, post-event follow-ups, social media campaigns, influencer partnerships.",
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Engagement Activities:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Live demonstrations, interactive sessions, and Q&A panels with experts.",
				},
			],
		},
		{
			body: [
				{
					type: "img",
					data: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2010.10.27.png?alt=media&token=8cb22dd6-f032-4035-983a-1e84d134d9de",
					width: 930.52,
					height: 522,
				},
			],
		},

		{
			body: [
				{
					type: "h1",
					text: "Executive Summary:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Brief overview of the event's purpose and objectives. High-level benefits to the client.",
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Event Introduction:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "The concept of the event and the need for the event based on market analysis and recent trends.",
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Event Objectives:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Brand awareness goals, client retention and acquisition targets, and media impressions and other KPIs.",
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Event Details:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Date and duration, venue and location details, expected audience demographics and size.",
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Press and Media:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Media partners and sponsors, press release strategy, media kits and resources, pre-event interviews, and press conferences. Post-event coverage strategy.",
				},
			],
		},

		{
			body: [
				{
					type: "h1",
					text: "Client Retention and Acquisition:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "VIP experiences for existing clients, incentives for referrals, feedback collection and post-event engagement.",
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Metrics and Analytics:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Pre-defined success metrics, tools and platforms for measurement, post-event report timeline.",
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Budget and Costing:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Detailed breakdown of all event costs, payment milestones and terms, potential additional costs and contingencies.",
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Sponsorship and Partnership Opportunities:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Potential sponsors and their benefits, partnership tiers and packages, promotion for sponsors at the event.",
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Roles and Responsibilities:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Team members and their roles, client's responsibilities, third-party vendors and their scope.",
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Logistics and Operations:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Event flow and schedule, security and safety measures, required licenses and permits.",
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Conclusion:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Reiterate the value proposition and provide a call to action for the client.",
				},
			],
		},
		{
			body: [
				{
					type: "h1",
					text: "Annexure/Appendices:",
				},
			],
		},
		{
			body: [
				{
					type: "p",
					text: "Mock-ups, images, or sketches of the event layout, previous event case studies, testimonials or references.",
				},
			],
		},
	],
	costEstimate: {
		clientDetails: "Elizabeth Arden",

		description: ``,

		lines: [
			{
				title: "Total esimated budget required",
				description: ``,
				price: "R 685,000",
			},

			{
				title: "Event sponsorship (Once off)",
				description: `three cabins (2 people per cabin) that can be used for team members and promotional staff, as well as the opportunity to use one of the given cabins in a social media giveaway where Oh Ship can be tagged.`,
				price: "",
			},
			{
				title: "Give away Items: 100-300 units of each branded item ",
				description: `(sunglasses + case, Bucket Hat and towel)`,
				price: "",
			},
			{
				title: "Onboard promo staff",
				description: `TBA`,
				price: "",
			},
			{
				title: "Social media campaign",
				description: `- Facebook Promo Ad
- Instagram Promo Ad
- TikTok Promo Ad`,
				price: "",
			},
			{
				title: "Online competition form",
				description: `- Web based form`,
				price: "",
			},
		],
		additional: ``,
		terms: `*MSC has asked that we do not sell any of the product ranges on board as they have their own ranges available in the spa. We are able to give samples via the room drops and allow passengers to test the products and drive sales with a coupon or code to use when they make an online purchase that is exclusive to this event.
      
Important Note: those going on board for this event will need a valid passport. South African citizens do not need a Visa but if a team member or giveaway winner is traveling on a different passport would need to check the regulations to ensure that they do not get removed from the boat or docks.`,

		expiry: moment("01/09/2023", "DD/MM/YYYY").format("DD MMM YYYY"),
	},
};

export const ohshipData = {
	projectType: "PR Event",
	projectTitle: "Oh Ship - White Tea skin solutions event ",
	pages: [
		{
			page: 1,
			top: [
				{
					type: "h1",
					text: "White Tea Skin Solutions:",
				},
				{
					type: "h2",
					text: "PR Event",
				},
			],
			left: [
				{
					type: "h2",
					text: "Introducing Elizabeth Arden onboard the SPLENDIDA during OH SHIP's renowned White Party.",
				},
				{
					type: "p",
					text: " Over the years, the White Party has evolved into a cornerstone of the OH SHIP experience, garnering significant anticipation and widespread attention on social media.",
				},
			],
			right: [
				{
					type: "img",
					data: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2001.55.07.webp?alt=media&token=b76e4c05-bc94-42ba-b3d1-8cea9ef5dcd7",
					width: 784,
					height: 874,
				},
			],
			bottom: [],
			backgroundColor: "#FFF",
			backgroundImage:
				"https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2001.55.07.webp?alt=media&token=b76e4c05-bc94-42ba-b3d1-8cea9ef5dcd7",
		},

		{
			page: 1,
			top: [
				{
					type: "h1",
					text: "EVENT",
				},
				{
					type: "h2",
					text: "Sponsorship",
				},
			],
			left: [
				{
					type: "p",
					text: "Sponsoring the White Party offers Elizabeth Arden a distinct advantage: direct access to an engaged audience of over three thousand attendees aboard the MSC Splendida. This event is more than just a promotion—it's an invitation for attendees to immerse themselves in the Elizabeth Arden experience. Surrounded by luxury, attendees will be introduced firsthand to the elegance and effectiveness of the White Tea Skin Solutions range, allowing them to truly understand and appreciate the brand's commitment to quality and luxury.",
				},
			],
			right: [
				{
					type: "img",
					data: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2Fevent.webp?alt=media&token=6c8dcd64-21a8-4ac3-8a0b-8d39ddb8a234",
					width: 623.54,
					height: 534,
				},
			],
			bottom: [],
			backgroundColor: "#FFF",
			backgroundImage: "",
		},

		{
			page: 1,
			top: [
				{
					type: "h1",
					text: "VISION",
				},
				{
					type: "h2",
					text: "All about the luxury",
				},
			],
			left: [
				{
					type: "p",
					text: "a day where every moment is curated to envelop guests in an atmosphere of sophistication and elegance. Aboard this majestic vessel, attendees will not just witness, but deeply feel the exclusive allure synonymous with the White Tea Skin Solutions. Every touch, every interaction, every sensation is designed to resonate with the brand's commitment to unmatched luxury. ",
				},
			],
			right: [
				{
					type: "img",
					data: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2002.20.16.webp?alt=media&token=9f6de243-5f07-4d6c-a141-afebd30bdb40",
					width: 477,
					height: 498,
				},
			],
			bottom: [],
			backgroundColor: "#FFF",
			backgroundImage: "",
		},
		{
			page: 1,
			top: [
				{
					type: "h1",
					text: "Promotion",
				},
				{
					type: "h2",
					text: "Elizabeth Arden & White Tea Skin Solutions",
				},
			],
			left: [
				{
					type: "p",
					text: "Boosting brand recognition and product familiarity is at the core of our strategy. Through hands-on product trials, exclusive giveaways, enticing photo opportunities, and the allure of special offers—be it discounts or complimentary gifts with a purchase—we aim to captivate both our loyal and potential customers. This engagement-centric approach not only promotes the versatility of Elizabeth Arden's 'White Tea Skin Solutions' but also shines a spotlight on the brand's comprehensive range of products.",
				},
			],
			right: [],
			bottom: [
				{
					type: "img",
					data: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2010.03.46.png?alt=media&token=2070758d-2712-4ca4-b959-b0c1ee3e499f",
					width: 930.52,
					height: 450,
				},
			],
			backgroundColor: "#FFF",
			backgroundImage: "",
		},
		{
			page: 1,
			top: [
				{
					type: "h1",
					text: "Engagement Activities:",
				},
			],
			left: [
				{
					type: "p",
					text: "Live demonstrations, interactive sessions, and Q&A panels with experts.",
				},
				{
					type: "p",
					text: "Moreover, this event is designed to magnify the brand's exposure, particularly focusing on its new launch via social media platforms. By encouraging the use of event-specific hashtags, capitalizing on reposts, and creating promotional videos from event content, we're leveraging the power of digital reach. Furthermore, our curated environment onboard offers attendees a prime setting to share their unique experiences, bringing Elizabeth Arden's essence to a wider audience who can then disseminate it through their own social channels.",
				},
			],
			right: [],
			bottom: [
				{
					type: "img",
					data: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2010.10.27.png?alt=media&token=8cb22dd6-f032-4035-983a-1e84d134d9de",
					width: 930.52,
					height: 350,
				},
			],
			backgroundColor: "#FFF",
			backgroundImage: "",
		},

		// {
		// 	body: [
		// 		{
		// 			type: "p",
		// 			text: "Brand awareness goals, client retention and acquisition targets, and media impressions and other KPIs.",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "h1",
		// 			text: "Event Details:",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "p",
		// 			text: "Date and duration, venue and location details, expected audience demographics and size.",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "h1",
		// 			text: "Press and Media:",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "p",
		// 			text: "Media partners and sponsors, press release strategy, media kits and resources, pre-event interviews, and press conferences. Post-event coverage strategy.",
		// 		},
		// 	],
		// },

		// {
		// 	body: [
		// 		{
		// 			type: "h1",
		// 			text: "Client Retention and Acquisition:",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "p",
		// 			text: "VIP experiences for existing clients, incentives for referrals, feedback collection and post-event engagement.",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "h1",
		// 			text: "Metrics and Analytics:",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "p",
		// 			text: "Pre-defined success metrics, tools and platforms for measurement, post-event report timeline.",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "h1",
		// 			text: "Budget and Costing:",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "p",
		// 			text: "Detailed breakdown of all event costs, payment milestones and terms, potential additional costs and contingencies.",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "h1",
		// 			text: "Sponsorship and Partnership Opportunities:",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "p",
		// 			text: "Potential sponsors and their benefits, partnership tiers and packages, promotion for sponsors at the event.",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "h1",
		// 			text: "Roles and Responsibilities:",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "p",
		// 			text: "Team members and their roles, client's responsibilities, third-party vendors and their scope.",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "h1",
		// 			text: "Logistics and Operations:",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "p",
		// 			text: "Event flow and schedule, security and safety measures, required licenses and permits.",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "h1",
		// 			text: "Conclusion:",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "p",
		// 			text: "Reiterate the value proposition and provide a call to action for the client.",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "h1",
		// 			text: "Annexure/Appendices:",
		// 		},
		// 	],
		// },
		// {
		// 	body: [
		// 		{
		// 			type: "p",
		// 			text: "Mock-ups, images, or sketches of the event layout, previous event case studies, testimonials or references.",
		// 		},
		// 	],
		// },
	],
	costEstimate: {
		clientDetails: "Elizabeth Arden",

		description: ``,

		lines: [
			{
				title: "Total esimated budget required",
				description: ``,
				price: "R 535,000",
			},

			{
				title: "Event sponsorship (Once off)",
				description: `three cabins (2 people per cabin) that can be used for team members and promotional staff, as well as the opportunity to use one of the given cabins in a social media giveaway where Oh Ship can be tagged.`,
				price: "",
			},
			{
				title: "Give away Items: 100-300 units of each branded item ",
				description: `(sunglasses + case, Bucket Hat and towel)`,
				price: "",
			},
			{
				title: "Onboard promo staff",
				description: `TBA`,
				price: "",
			},
			{
				title: "Social media campaign",
				description: `- Facebook Promo Ad
- Instagram Promo Ad
- TikTok Promo Ad`,
				price: "",
			},
			{
				title: "Online competition form",
				description: `- Web based form`,
				price: "",
			},
		],
		additional: ``,
		terms: `*MSC has asked that we do not sell any of the product ranges on board as they have their own ranges available in the spa. We are able to give samples via the room drops and allow passengers to test the products and drive sales with a coupon or code to use when they make an online purchase that is exclusive to this event.
      
Important Note: those going on board for this event will need a valid passport. South African citizens do not need a Visa but if a team member or giveaway winner is traveling on a different passport would need to check the regulations to ensure that they do not get removed from the boat or docks.`,

		expiry: moment("01/09/2023", "DD/MM/YYYY").format("DD MMM YYYY"),
	},
};

export const retinolevent = {
	projectType: "RETINOL Watercream - PR Events",
	projectTitle: "RETINOL + HPR",

	pages: [
		{
			page: 1,
			top: [
				{
					type: "h1",
					text: "White Tea Skin Solutions:",
				},
				{
					type: "h2",
					text: "PR Event",
				},
			],
			left: [
				{
					type: "pdf",
					data: "https://drive.google.com/open?id=1KjusnhS78Fr7hU0S3C-oYPqlOanjJyZx&usp=drive_fs",
				},
			],
			right: [],
			bottom: [],
			backgroundColor: "#FFF",
			backgroundImage:
				"https://drive.google.com/open?id=1KjusnhS78Fr7hU0S3C-oYPqlOanjJyZx&usp=drive_fs",
		},
	],
	costEstimate: {
		clientDetails: "Elizabeth Arden",

		description: ``,

		lines: [
			{
				title: "Total esimated budget required",
				description: ``,
				price: "R 535,000",
			},

			{
				title: "Event sponsorship (Once off)",
				description: `three cabins (2 people per cabin) that can be used for team members and promotional staff, as well as the opportunity to use one of the given cabins in a social media giveaway where Oh Ship can be tagged.`,
				price: "",
			},
			{
				title: "Give away Items: 100-300 units of each branded item ",
				description: `(sunglasses + case, Bucket Hat and towel)`,
				price: "",
			},
			{
				title: "Onboard promo staff",
				description: `TBA`,
				price: "",
			},
			{
				title: "Social media campaign",
				description: `- Facebook Promo Ad
- Instagram Promo Ad
- TikTok Promo Ad`,
				price: "",
			},
			{
				title: "Online competition form",
				description: `- Web based form`,
				price: "",
			},
		],
		additional: ``,
		terms: `*MSC has asked that we do not sell any of the product ranges on board as they have their own ranges available in the spa. We are able to give samples via the room drops and allow passengers to test the products and drive sales with a coupon or code to use when they make an online purchase that is exclusive to this event.
      
Important Note: those going on board for this event will need a valid passport. South African citizens do not need a Visa but if a team member or giveaway winner is traveling on a different passport would need to check the regulations to ensure that they do not get removed from the boat or docks.`,

		expiry: moment("01/09/2023", "DD/MM/YYYY").format("DD MMM YYYY"),
	},
};

export const digitalRetainer = {
	projectType: "Digital Retainer",
	projectTitle: "RETINOL + HPR",

	pages: [
		{
			page: 1,
			top: [
				{
					type: "h1",
					text: "Digital Support Retainer:",
				},

				{
					type: "h2",
					text: "REVLON & Elizabeth Arden",
				},
			],
			left: [
				{
					type: "h2",
					text: "Overview:",
				},
				{
					type: "h2",
					text: "REVLON & Elizabeth Arden",
				},
				{
					type: "p",
					text: " Over the years, the White Party has evolved into a cornerstone of the OH SHIP experience, garnering significant anticipation and widespread attention on social media.",
				},
			],
			right: [
				{
					type: "img",
					data: "https://www.revlon.co.za/",
					width: 784,
					height: 874,
				},
			],
			bottom: [],
			backgroundColor: "#FFF",
			backgroundImage:
				"https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2001.55.07.webp?alt=media&token=b76e4c05-bc94-42ba-b3d1-8cea9ef5dcd7",
		},
		// {
		// 	page: 1,
		// 	top: [
		// 		{
		// 			type: "h1",
		// 			text: "Digital platform management:",
		// 		},
		// 		{
		// 			type: "h2",
		// 			text: "Digital",
		// 		},
		// 	],
		// 	left: [
		// 		{
		// 			type: "pdf",
		// 			data: "https://drive.google.com/open?id=1KjusnhS78Fr7hU0S3C-oYPqlOanjJyZx&usp=drive_fs",
		// 		},
		// 	],
		// 	right: [],
		// 	bottom: [],
		// 	backgroundColor: "#FFF",
		// 	backgroundImage:
		// 		"https://drive.google.com/open?id=1KjusnhS78Fr7hU0S3C-oYPqlOanjJyZx&usp=drive_fs",
		// },
	],
	costEstimate: {
		clientDetails: "Elizabeth Arden",
		description: ``,
		lines: [
			{
				title: "Total retainer:",
				description: ``,
				price: "R 535,000",
			},

			{
				title: "Event sponsorship (Once off)",
				description: `three cabins (2 people per cabin) that can be used for team members and promotional staff, as well as the opportunity to use one of the given cabins in a social media giveaway where Oh Ship can be tagged.`,
				price: "",
			},
			{
				title: "Give away Items: 100-300 units of each branded item ",
				description: `(sunglasses + case, Bucket Hat and towel)`,
				price: "",
			},
			{
				title: "Onboard promo staff",
				description: `TBA`,
				price: "",
			},
			{
				title: "Social media campaign",
				description: `- Facebook Promo Ad
- Instagram Promo Ad
- TikTok Promo Ad`,
				price: "",
			},
			{
				title: "Online competition form",
				description: `- Web based form`,
				price: "",
			},
		],
		additional: ``,
		terms: `*MSC has asked that we do not sell any of the product ranges on board as they have their own ranges available in the spa. We are able to give samples via the room drops and allow passengers to test the products and drive sales with a coupon or code to use when they make an online purchase that is exclusive to this event.
      
Important Note: those going on board for this event will need a valid passport. South African citizens do not need a Visa but if a team member or giveaway winner is traveling on a different passport would need to check the regulations to ensure that they do not get removed from the boat or docks.`,

		expiry: moment("01/09/2023", "DD/MM/YYYY").format("DD MMM YYYY"),
	},
};

export const arVrActivation = {
	projectType: "Hydraplay AR/VR Experience",
	projectTitle: "VR Activation ",
	pages: [
		{
			page: 1,
			top: [
				{
					type: "h1",
					text: "Hydraplay AR/VR Activation",
				},
			],
			left: [
				{
					type: "h1",
					text: "Immersive Technology Showcase Proposal",
				},
				{
					type: "h2",
					text: "Objective",
				},
				{
					type: "p",
					text: `The objective of this proposal is to utilize cutting-edge immersive technologies, specifically Virtual Reality (VR) and Augmented Reality (AR), to enhance customer engagement and product visibility for [Product/Brand Name].`,
				},
				{
					type: "h2",
					text: "Components of the Activation",
				},
				{
					type: "h3",
					text: "Virtual Reality (VR) Game",
				},
				{
					type: "p",
					text: `A VR game that immerses participants in a virtual world designed specifically to highlight the features of [Product/Brand Name]. This segment will include interactive challenges and educational elements about the product.`,
				},
				{
					type: "h3",
					text: "Augmented Reality (AR) Cellphone App",
				},
				{
					type: "p",
					text: `An AR application accessible via web browsers on smartphones, enabling users to interact with [Product/Brand Name] in their actual environment. The app will provide product visualization, interactive features, and detailed overlays.`,
				},
				{
					type: "h3",
					text: "Giveaway of VR Headsets",
				},
				{
					type: "p",
					text: `Participants will receive cost-effective yet functional VR headsets as giveaways, encouraging continued engagement with the brand and the virtual experiences from their homes.`,
				},
				{
					type: "h2",
					text: "Expected Outcomes",
				},
				{
					type: "p",
					text: `This activation aims to significantly increase interaction with [Product/Brand Name], enhance product understanding among consumers, and extend brand exposure through personal VR experiences.`,
				},
				{
					type: "h2",
					text: "Target Audience",
				},
				{
					type: "p",
					text: `The primary target audience includes tech-savvy individuals and prospective customers interested in innovative ways to interact with products.`,
				},
				{
					type: "h2",
					text: "Logistics",
				},
				{
					type: "p",
					text: `Details on the venue, necessary equipment, and staffing requirements needed to effectively execute the proposed activation.`,
				},
			],
			right: [
				// {
				// 	type: "img",
				// 	data: "https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2001.55.07.webp?alt=media&token=b76e4c05-bc94-42ba-b3d1-8cea9ef5dcd7",
				// 	width: 784,
				// 	height: 874,
				// },
			],
			bottom: [
				{
					type: "p",
					text: "garnering significant anticipation and widespread attention o",
				},
			],
			backgroundColor: "#FFF",
			backgroundImage:
				"https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2001.55.07.webp?alt=media&token=b76e4c05-bc94-42ba-b3d1-8cea9ef5dcd7",
		},
	],
	costEstimate: {
		clientDetails: "Elizabeth Arden",

		description: ``,

		lines: [
			{
				title: "VR Headsets",
				description: `Metaquest Pro V4`,
				qty: 1,
				price: "130000",
			},
			{
				title: "Apple vision pro",
				description: `three cabins (2 people per cabin) that can be used for team members and promotional staff, as well as the opportunity to use one of the given cabins in a social media giveaway where Oh Ship can be tagged.`,
				qty: 3,
				price: "150000",
			},
		],
		additional: ``,
		terms: `* sjkfdnvjsdnfv`,

		expiry: moment("01/06/2024", "DD/MM/YYYY").format("DD MMM YYYY"),
	},
};
