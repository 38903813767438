import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Font from "react-font";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/76961-weather-alert-notification.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";

import moment from "moment";
import ImageLoop from "./ImageLoop";
import LandingPage from "./LandingPage";

export default class Proposals extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inView: false,
			clientLogo: "",
			pages: this.props.data,

			costEstimate: this.props.costEstimate,
		};
	}

	pages = (d, h1, h2, pp) => {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = window.innerHeight;
		let w = window.innerWidth > 1000 ? 1000 : window.innerWidth;

		let imgWid = w / d.body.length > 400 ? 400 : w / d.body.length;

		return (
			<div
				style={{
					width: window.innerWidth,
					height: "auto",
					justifyContent: "center",
					alignItems: "center",
					zIndex: 5,
					// padding: "5%",
				}}>
				<View
					style={{
						height: "auto",
						width: "100%",
						zIndex: 1,
						maxWidth: 1000,
						paddingLeft: "5%",
						paddingRight: "5%",
						marginLeft: "auto",
						marginRight: "auto",
						marginBottom: pp,
					}}>
					<View
						style={{
							flexDirection: "row",

							width: "100%",
							justifyContent: "space-between",
							// marginBottom: 10,
						}}>
						{d.body.map((dd) =>
							dd.type === "img" ? (
								<Image
									source={dd.data}
									style={{
										width: imgWid * (dd.width / dd.height),
										height: imgWid,
										maxWidth: dd.width,
										maxHeight: dd.height,
										resizeMode: "contain",
										marginBottom: h1,
									}}
								/>
							) : dd.type === "h1" ? (
								<View
									style={{
										flex: 1,
										// marginTop: h1,
										// marginBottom: h1,
									}}>
									<Text
										style={{
											fontSize: h1,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={600}>
											{dd.text}
										</Font>
									</Text>
									<Text
										style={{
											fontSize: h2,
											marginBottom: h2,
											flexDirection: "row",
											opacity: 0.4,
											color: "#000",
										}}>
										<Font family='Montserrat' weight={500}>
											{dd.subtitle}
										</Font>
									</Text>
								</View>
							) : dd.type === "h2" ? (
								<View
									style={{
										flex: 1,
										// marginTop: h2,
										// marginBottom: h2,
									}}>
									<Text
										style={{
											fontSize: h2,
											color: APP_COLOURS.TEXTCOLOR,

											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={500}>
											{dd.text}
										</Font>
									</Text>
								</View>
							) : dd.type === "p" ? (
								<Text
									style={{
										flex: 1,
										fontSize: pp,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: pp,
										fontFamily: "Montserrat",
										textAlign: "justify",
										fontWeight: "300",
									}}>
									{/* <Font
													family='Montserrat'
													weight={300}> */}
									{dd.text}
									{/* </Font> */}
								</Text>
							) : dd.type === "li" ? (
								<View
									style={{
										flex: 1,
									}}>
									<Text
										style={{
											fontSize: pp,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 5,
											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={500}>
											{dd.text}
										</Font>
									</Text>
									<Text
										style={{
											fontSize: pp,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 15,
											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={300}>
											{dd.subtitle}
										</Font>
									</Text>
									{dd.items.map((ddd) => (
										<>
											<Text
												style={{
													fontSize: pp,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
													// paddingLeft: 15,
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													- {ddd.text}
												</Font>
											</Text>
											<Text
												style={{
													fontSize: pp,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: pp,
													flexDirection: "row",
													paddingLeft: pp,
												}}>
												<Font
													family='Montserrat'
													weight={300}>
													{ddd.subtitle}
												</Font>
											</Text>
										</>
									))}
								</View>
							) : dd.type === "slide" ? (
								<View
									style={{
										flex: 1,
									}}>
									<ImageLoop
										steps={dd.items}
										stepTitle={(title) =>
											this.setState({
												stepTitle: title,
											})
										}
									/>
								</View>
							) : (
								<View
									style={{
										flex: 1,
									}}
								/>
							),
						)}
					</View>
				</View>
			</div>
		);
	};

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = window.innerHeight;
		let w = window.innerWidth > 1000 ? 1000 : window.innerWidth;

		let ce = this.state.costEstimate;

		let fs = window.innerWidth * 0.07;
		let maxFont = 35;
		let h1 = fs < maxFont ? fs : maxFont;
		let h2 = h1 * 0.7;
		let pp = h1 * 0.6;

		return (
			<View
				style={{
					overflow: "hidden",
					// backgroundColor: "#EDF1F4",
					width: window.innerWidth,
				}}>
				<LandingPage {...this.state} h1={h1} h2={h2} pp={pp} />
				<View
					style={{
						width: window.innerWidth,
						backgroundColor: "#FFF",
						// backgroundColor: "red",
						borderRadius: 30,
						justifyContent: "center",
						alignItems: "center",
						height: window.innerHeight * 0.1,
						top: -window.innerHeight * 0.05,
					}}
				/>
				{this.state.pages.map((d) => this.pages(d, h1, h2, pp))}
				<div
					style={{
						// flex: 1,
						width: window.innerWidth,
						justifyContent: "center",
						alignItems: "center",
						paddingTop: "5%",
					}}>
					<View
						style={{
							position: "absolute",
							bottom: 0,
							left: 0,
						}}>
						<Image
							source={require("./assets/dots.png")}
							style={{
								width: 500,
								height: 500,
								resizeMode: "contain",
							}}
						/>
					</View>
					<View
						style={{
							position: "absolute",
							top: window.innerHeight * 0.8,
							right: 0,
							zIndex: 1,
						}}>
						<Image
							source={require("./assets/dots.png")}
							style={{
								width: 500,
								height: 500,
								resizeMode: "contain",
							}}
						/>
					</View>
					<View
						style={{
							position: "absolute",
							bottom: 84,
							right: 0,
						}}>
						<Image
							source={require("./assets/agreeMent.png")}
							style={{
								width: 200 * (368.94 / 255.42),
								height: 200,
								resizeMode: "contain",
							}}
						/>
					</View>
					<View
						style={{
							width: "100%",
							backgroundColor: "#1F2229",
							height: 85,
							position: "absolute",
							bottom: 0,
							left: 0,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								width: "100%",
								flexDirection: "row",
								maxWidth: 1000,
								justifyContent: "space-between",
								paddingLeft: 10,
								paddingRight: 10,
							}}>
							<Image
								source={require("./assets/services.png")}
								style={{
									width: 250,
									maxWidth: window.innerWidth * 0.4,
									height: 35,
									resizeMode: "contain",
								}}
							/>
							<Image
								source={require("./assets/infod.png")}
								style={{
									width: 220,
									maxWidth: window.innerWidth * 0.4,
									height: 35,
									resizeMode: "contain",
								}}
							/>
						</View>
					</View>

					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							paddingBottom: 350,
						}}>
						<View
							style={{
								width: "100%",

								// height: h,
								height: "auto",
								maxWidth: 1000,

								flex: 1,
								zIndex: 1,
								padding: "5%",
							}}>
							<View
								style={{
									// flex: 1,
									flexDirection: "row",
									// height: h * 0.2,
									width: "100%",
									// marginBottom: "40%",
								}}>
								<View
									style={{
										flex: 1,
										// padding: 20,
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={{
												fontSize: h1,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											<Font family='Poppins' weight={500}>
												{ce.clientDetails}
											</Font>
										</Text>
										<Text
											style={{
												fontSize: h1,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											<Font family='Poppins' weight={700}>
												{ce.title}
											</Font>
										</Text>
									</View>
								</View>
							</View>

							<View
								style={{
									width: "100%",
								}}>
								{ce.lines.map((dd) => (
									<View
										style={{
											marginTop: 20,
											marginBottom: 20,
											paddingBottom: 10,
											borderBottomWidth: 0.5,
											borderColor: APP_COLOURS.TEXTCOLOR,
										}}>
										<View
											style={{
												flexDirection: "row",
												justifyContent: "space-between",
											}}>
											<Text
												style={{
													fontSize: pp,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Montserrat'
													weight={700}>
													{dd.title}
												</Font>
											</Text>
											<Text
												style={{
													fontSize: pp,
													color: APP_COLOURS.TEXTCOLOR,

													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={700}>
													{dd.price}
												</Font>
											</Text>
										</View>

										<Text
											style={{
												fontSize: pp,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												{dd.description}
											</Font>
										</Text>
									</View>
								))}
							</View>

							{/* <View
								style={{
									width: "100%",
									borderWidth: 0.5,
									borderColor: APP_COLOURS.TEXTCOLOR,
								}}
							/> */}

							<Text
								style={{
									fontSize: pp,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
									marginTop: 2,
								}}>
								<Font family='Montserrat' weight={300}>
									{ce.additional}
								</Font>
							</Text>

							<Text
								style={{
									fontSize: pp,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
									marginTop: 2,
								}}>
								<Font family='Montserrat' weight={300}>
									{ce.terms}
								</Font>
							</Text>

							<Text
								style={{
									fontSize: pp - 2,
									color: moment().isAfter(moment(ce.expiry))
										? APP_COLOURS.RED
										: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
									marginTop: 50,
								}}>
								<Font family='Montserrat' weight={300}>
									* This quotation is valid until {ce.expiry}
									{moment().isAfter(moment(ce.expiry))
										? ", and has expired"
										: `, expires ${moment(
												ce.expiry,
										  ).fromNow()}`}
								</Font>
							</Text>

							<Text
								style={{
									fontSize: pp - 2,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									* All quoted amounts are based in USD/ZAR
									exchange rate at the time of quoting and may
									be subject to change
								</Font>
							</Text>

							<Text
								style={{
									fontSize: pp - 2,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									* All quoted amounts are monthly recurring
									and exclusive of VAT unless stated
									otherwise.
								</Font>
							</Text>
						</View>
					</View>
				</div>
			</View>
		);
	}
}
