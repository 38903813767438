import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Font from "react-font";
import Fade from "react-reveal/Fade";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import logo_tetrice from "./assets/logo_tetrice.png";
// import { Document, Page } from "react-pdf";
import * as animationData2 from "./assets/animation_lm5gydv9.json";
import * as socialIcons from "./assets/animation_lm5hhia2.json";

import "./resizeScale.scss";
import "./hoverEffect.scss";

import moment from "moment";
import ImageLoop from "./ImageLoop";
import ProposalHeading from "./ProposalHeading";
import ProposalHeading2 from "./ProposalHeading2";
import MultiPagePDFExport from "./PdfExport";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

export default class MainProposal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projectType: "test",
			projectTitle: "test",
			pages: [
				{
					page: 1,
					top: [],
					left: [],
					right: [],
					bottom: [],
					backgroundColor: "#FFF",
					backgroundImage:
						"https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/proposalAssets%2FScreenshot%202023-08-29%20at%2001.55.07.webp?alt=media&token=b76e4c05-bc94-42ba-b3d1-8cea9ef5dcd7",
				},
			],

			costEstimate: {
				clientDetails: "Elizabeth Arden",

				description: ``,

				lines: [
					{
						title: "Total esimated budget required",
						description: ``,
						price: "R 685,000",
					},

					{
						title: "Event sponsorship (Once off)",
						description: `three cabins (2 people per cabin) that can be used for team members and promotional staff, as well as the opportunity to use one of the given cabins in a social media giveaway where Oh Ship can be tagged.`,
						price: "",
					},
					{
						title: "Give away Items: 100-300 units of each branded item ",
						description: `(sunglasses + case, Bucket Hat and towel)`,
						price: "",
					},
					{
						title: "Onboard promo staff",
						description: `TBA`,
						price: "",
					},
					{
						title: "Social media campaign",
						description: `- Facebook Promo Ad
- Instagram Promo Ad
- TikTok Promo Ad`,
						price: "",
					},
					{
						title: "Online competition form",
						description: `- Web based form`,
						price: "",
					},
				],
				additional: ``,
				terms: `*MSC has asked that we do not sell any of the product ranges on board as they have their own ranges available in the spa. We are able to give samples via the room drops and allow passengers to test the products and drive sales with a coupon or code to use when they make an online purchase that is exclusive to this event.
				
Important Note: those going on board for this event will need a valid passport. South African citizens do not need a Visa but if a team member or giveaway winner is traveling on a different passport would need to check the regulations to ensure that they do not get removed from the boat or docks.`,

				expiry: moment("01/09/2023", "DD/MM/YYYY").format(
					"DD MMM YYYY",
				),
			},
		};
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		const defaultOptions3 = {
			loop: false,
			autoplay: true,
			animationData: socialIcons,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = window.innerHeight;
		let w =
			window.innerWidth > 500 ? window.innerWidth / 2 : window.innerWidth;

		let ce = this.state.costEstimate;

		let fs = window.innerWidth * 0.05;
		let minFont = window.innerHeight / 50;
		let pp = minFont;
		let h2 = pp * 1.168;
		let h1 = h2 * 1.168;

		return (
			<View
				style={{
					overflow: "hidden",
					// backgroundColor: "#EDF1F4",
					width: window.innerWidth,
					// backgroundColor: "#1C2B46",
					backgroundColor: "#171A20",
					// backgroundColor: "#FFF",
					height: window.innerHeight,
				}}>
				{/* <View
					style={{
						height: window.innerHeight * 0.3,
						width: "100%",
						backgroundColor: "#171A20",
						position: "absolute",
					}}
				/> */}
				<View
					style={{
						position: "absolute",
					}}>
					<ProposalHeading
						{...this.state}
						title={this.state.projectType}
						title2={this.state.projectTitle}
						h1={h1}
						h2={h2}
						pp={pp}
					/>
					{/* <ProposalHeading2
						{...this.state}
						title={this.state.projectType}
						title2={this.state.projectTitle}
						h1={h1}
						h2={h2}
						pp={pp}
					/> */}

					{/* <View
						style={{
							// flex: 1,
							flexDirection: "row",
							// height: h * 0.2,
							width: w,
							// marginBottom: "40%",
						}}>
						<View
							style={{
								flex: 1,
								// padding: 20,
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<Text
									style={{
										fontSize: 35,
										color: APP_COLOURS.TEXTCOLOR,
									}}>
									<Font family='Poppins' weight={700}>
										REVLON
									</Font>
								</Text>
								<Text
									style={{
										fontSize: 35,
										color: APP_COLOURS.TEXTCOLOR,
									}}>
									<Font family='Poppins' weight={500}>
										Community Management
									</Font>
								</Text>
							</View>
						</View>
					</View> */}
				</View>

				<ScrollView
					showsVerticalScrollIndicator={false}
					ref={(ref) => {
						this.scrollRef = ref;
					}}
					// overScrollMode='never'
					pagingEnabled={true}
					decelerationRate='fast'
					// snapToInterval={window.innerHeight}
					scrollEventThrottle={16}>
					<View
						style={{
							width: "100%",
							// backgroundColor: "#FFF",
							borderTopLeftRadius: 20,
							borderTopRightRadius: 20,
							// height: window.innerHeight * 0.15,
							justifyContent: "center",
							alignItems: "center",
							overflow: "hidden",
						}}>
						<View
							style={{
								backgroundColor: "transparent",
								height: window.innerHeight * 0.85,
							}}
						/>
						<View
							style={{
								width: "100%",
								backgroundColor: "#FFF",
								borderTopLeftRadius: 20,
								borderTopRightRadius: 20,
								height: window.innerHeight * 0.15,
								justifyContent: "center",
								alignItems: "center",
								overflow: "hidden",
								flexDirection: "row",
								paddingRight: "10%",
							}}>
							{/* <Image
								source={require("./assets/tetrice.png")}
								style={{
									width: 100,
									height: window.innerHeight * 0.1,
									resizeMode: "contain",
								}}
							/> */}
							{/* <Image
								source={require("./assets/tetrice_proudly.png")}
								style={{
									width: 150,
									height: 90,
									resizeMode: "contain",
								}}
							/> */}

							<Lottie
								options={defaultOptions3}
								height={window.innerHeight * 0.1}
								width={window.innerWidth * 0.95}
							/>
						</View>
					</View>
					{/* <MultiPagePDFExport> */}
					{this.state.pages.map((d) =>
						this.pages(w, h, d, h1, h2, pp),
					)}

					<div
						className='pdf-page-break'
						style={{
							width: window.innerWidth,
							// height: "auto",
							// justifyContent: "center",
							// alignItems: "center",
							zIndex: 5,
							// padding: "5%",
							backgroundColor: "#FFF",

							paddingTop: "5%",
						}}>
						<View
							style={{
								position: "absolute",
								bottom: 84,
								right: 0,
							}}>
							<Image
								source={require("./assets/agreeMent.png")}
								style={{
									width: 200 * (368.94 / 255.42),
									height: 200,
									resizeMode: "contain",
								}}
							/>
						</View>
						<View
							style={{
								width: "100%",
								backgroundColor: "#1C2B46",
								height: 85,
								position: "absolute",
								bottom: 0,
								left: 0,
								justifyContent: "center",
								alignItems: "center",
								// paddingBottom: 20,
							}}>
							<View
								style={{
									width: "100%",
									flexDirection: "row",
									maxWidth: 1200,
									justifyContent: "space-between",
									paddingLeft: 10,
									paddingRight: 10,
								}}>
								<Image
									source={require("./assets/services.png")}
									style={{
										width: 250,
										maxWidth: window.innerWidth * 0.4,
										height: 35,
										resizeMode: "contain",
									}}
								/>
								<Image
									source={require("./assets/infod.png")}
									style={{
										width: 220,
										maxWidth: window.innerWidth * 0.4,
										height: 35,
										resizeMode: "contain",
									}}
								/>
							</View>
						</View>

						<View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
								paddingBottom: 350,
								borderTopWidth: 0.5,
								borderTopColor: "#171A20",
							}}>
							<View
								style={{
									width: "100%",

									// height: h,
									height: "auto",
									maxWidth: 1200,

									flex: 1,
									zIndex: 1,
									padding: "5%",

									display: this.props.showCE
										? "flex"
										: "none",
								}}>
								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h * 0.2,
										width: "100%",
										// marginBottom: "40%",
									}}>
									<View
										style={{
											flex: 1,
											// padding: 20,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													fontSize: h1 * 0.8,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={500}>
													Cost breakdown
												</Font>
											</Text>

											<Text
												style={{
													fontSize: h1,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: h1,
												}}>
												<Font
													family='Poppins'
													weight={700}>
													{this.state.projectTitle}
												</Font>
											</Text>
											<Text
												style={{
													fontSize: pp,
													color: APP_COLOURS.TEXTCOLOR,
													fontFamily: "Avenir",
												}}>
												{ce.description}
											</Text>
										</View>
									</View>
								</View>

								<View
									style={{
										width: "100%",
									}}>
									{ce.lines.map((dd) => (
										<View
											style={{
												marginTop: pp,
												marginBottom: pp,
												paddingBottom: pp,
												borderBottomWidth: 0.5,
												borderColor:
													APP_COLOURS.TEXTCOLOR,
											}}>
											<View
												style={{
													flexDirection: "row",
													justifyContent:
														"space-between",
												}}>
												<Text
													style={{
														fontSize: pp,
														color: APP_COLOURS.TEXTCOLOR,
													}}>
													<Font
														family='Montserrat'
														weight={700}>
														{dd.title}
													</Font>
												</Text>
												<Text
													style={{
														minWidth: 80,
														fontSize: pp,
														color: APP_COLOURS.TEXTCOLOR,
														textAlign: "right",
														flexDirection: "row",
													}}>
													<Font
														family='Montserrat'
														weight={700}>
														{dd.price}
													</Font>
												</Text>
											</View>

											<Text
												style={{
													fontSize: pp,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Montserrat'
													weight={300}>
													{dd.description}
												</Font>
											</Text>
										</View>
									))}
								</View>

								<Text
									style={{
										fontSize: pp,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 2,
										flexDirection: "row",
										marginTop: 2,
									}}>
									<Font family='Montserrat' weight={300}>
										{ce.additional}
									</Font>
								</Text>

								<Text
									style={{
										fontSize: pp,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 2,
										flexDirection: "row",
										marginTop: 2,
									}}>
									<Font family='Montserrat' weight={300}>
										{ce.terms}
									</Font>
								</Text>

								<Text
									style={{
										fontSize: pp - 2,
										color: moment().isAfter(
											moment(ce.expiry),
										)
											? APP_COLOURS.RED
											: APP_COLOURS.TEXTCOLOR,
										marginBottom: 2,
										flexDirection: "row",
										marginTop: 50,
									}}>
									<Font family='Montserrat' weight={300}>
										* This quotation is valid until{" "}
										{ce.expiry}
										{moment().isAfter(moment(ce.expiry))
											? ", and has expired"
											: `, expires ${moment(
													ce.expiry,
											  ).fromNow()}`}
									</Font>
								</Text>

								<Text
									style={{
										fontSize: pp - 2,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 2,
										flexDirection: "row",
									}}>
									<Font family='Montserrat' weight={300}>
										* All quoted amounts are based in
										USD/ZAR exchange rate at the time of
										quoting and may be subject to change
									</Font>
								</Text>

								<Text
									style={{
										fontSize: pp - 2,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 2,
										flexDirection: "row",
									}}>
									<Font family='Montserrat' weight={300}>
										* All quoted amounts are exclusive of
										VAT unless stated otherwise.
									</Font>
								</Text>
							</View>
						</View>
					</div>
					{/* </MultiPagePDFExport> */}
				</ScrollView>
				{/* <View
					style={{
						width: "100%",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: "row",
						position: "absolute",
						zIndex: 99,
						paddingLeft: "5%",
						paddingRight: "5%",
						backgroundColor: "#FFF",
						maxHeight: 50,
					}}>
					<Image
						source={require("./assets/tetrice.png")}
						style={{
							width: w * 0.15,
							height: w * 0.15 * (50 / 178.9),
							resizeMode: "contain",
							maxWidth: 350,
						}}
					/>
					<Image
						source={require("./assets/revlon_ea_logo.png")}
						style={{
							width: window.innerWidth * 0.25,
							height: window.innerWidth * 0.25 * (150 / 300),
							resizeMode: "contain",
							maxWidth: 350,
						}}
					/>
				</View> */}
				{/* <View
					style={{
						width: window.innerWidth,
						backgroundColor: "#171A20",
						borderRadius: 30,
						justifyContent: "flex-end",
						alignItems: "center",
						height: window.innerHeight * 0.12,
						top: -window.innerHeight * 0.06,
						position: "absolute",
						padding: 10,
					}}>
					<Image
						source={logo_tetrice}
						style={{
							width: "50%",
							height: window.innerHeight * 0.03,
							resizeMode: "contain",
						}}
					/>
				</View> */}
				{/* <View
					style={{
						width: window.innerWidth,
						backgroundColor: "#FFF",
						// backgroundColor: "#1C2B46",
						borderRadius: 24,
						justifyContent: "center",
						alignItems: "center",
						height: window.innerHeight * 0.1,
						bottom: -window.innerHeight * 0.05,
						position: "absolute",
					}}
				/> */}
			</View>
		);
	}

	pages = (w, h, d, h1, h2, pp) => {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		// let imgWid = w / d.body.length;

		let pdTop = 30;

		let presentationMode = window.innerWidth > window.innerHeight;

		return (
			<div
				className='pdf-page-break'
				style={{
					// flex: 1,
					width: "100%",
					height: window.innerHeight,
					justifyContent: "center",
					// alignItems: "center",
					zIndex: 5,
					// padding: "5%",
					backgroundColor: d.backgroundColor,
					// paddingTop: pdTop,
					borderBottomWidth: 1,
				}}>
				<View
					style={{
						width: "100%",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: "row",
						// position: "absolute",
						// zIndex: 99,
						// top: 0,
						paddingLeft: "5%",
						paddingRight: "5%",
						backgroundColor: "#FFF",
						height: window.innerHeight * 0.07,
						// borderBottomColor: "#171A20",
						// borderBottomWidth: 1,
						borderTopWidth: 1,
						overflow: "hidden",
						backgroundColor: "#171A20",
					}}>
					<Image
						source={require("./assets/logoTetrice.png")}
						style={{
							width: w * 0.15,
							height: w * 0.15 * (50 / 178.9),
							resizeMode: "contain",
							maxWidth: 350,
						}}
					/>

					{/* <Image
						source={require("./assets/tetrice-revlon-main.png")}
						style={{
							width: 200,
							height: 110 * (153 / 461.3),
							resizeMode: "contain",
							borderRadius: 10,
						}}
					/> */}
				</View>
				<View
					style={{
						height: window.innerHeight - window.innerHeight * 0.07,
						padding: "5%",
						paddingTop: window.innerHeight * 0.05,
					}}>
					<View
						style={{
							// justifyContent: "center",
							// alignItems: "center",
							marginBottom: window.innerHeight * 0.05,
							paddingRight: "2%",
						}}>
						{d.top.map((dd) =>
							this.content(dd, w, h, d, h1, h2, pp),
						)}
					</View>
					{presentationMode ? (
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								// padding: "2%",
							}}>
							<View
								style={{
									flex: 1,
									display:
										d.left.length > 0 ? "flex" : "none",
									paddingRight: "2%",
								}}>
								{d.left.map((dd) =>
									this.content(
										dd,
										w,
										h,
										d,
										h1,
										h2,
										pp,
										d.right.length,
									),
								)}
							</View>
							<View
								style={{
									display:
										d.right.length > 0 ? "flex" : "none",
									flex: 1,
									justifyContent: "flex-end",
									paddingRight: "2%",
								}}>
								{d.right.map((dd) =>
									this.content(
										dd,
										w,
										h,
										d,
										h1,
										h2,
										pp,
										d.right.length,
									),
								)}
							</View>
						</View>
					) : (
						<View style={{}}>
							<View
								style={{
									flex: 1,
									display:
										d.left.length > 0 ? "flex" : "none",
								}}>
								{d.left.map((dd) =>
									this.content(
										dd,
										w,
										h,
										d,
										h1,
										h2,
										pp,
										d.right.length,
									),
								)}
							</View>
							<View
								style={{
									display:
										d.right.length > 0 ? "flex" : "none",
									flex: 1,
									justifyContent: "flex-end",
								}}>
								{d.right.map((dd) =>
									this.content(
										dd,
										w,
										h,
										d,
										h1,
										h2,
										pp,
										d.right.length,
									),
								)}
							</View>
						</View>
					)}

					{/* <View
						style={{
							position: "absolute",
							bottom: 0,
							left: 0,
							width: "100%",
						}}>
						<Lottie
							options={defaultOptions}
							height={h * 0.4}
							width={window.innerWidth}
						/>
					</View> */}

					<View
						style={{
							// height: 200,
							width: "100%",
							display: d.bottom.length > 0 ? "flex" : "none",
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						{d.bottom.map((dd) =>
							this.content(
								dd,
								w,
								h,
								d,
								h1,
								h2,
								pp,
								d.bottom.length,
							),
						)}
					</View>
				</View>
			</div>
		);
	};

	content = (dd, w, h, d, h1, h2, pp, length) => {
		let div_ = length > 1 ? 2 : 1;
		w = w / length - 40;

		return (
			<View
				style={
					{
						// backgroundColor: dd.backgroundColor,
						// padding: "5%",
						// paddingVertical: "2%",
						// paddingHorizontal: "5%",
						// borderRadius: 10,
					}
				}>
				<Fade>
					{dd.type === "img" ? (
						<View
							style={{
								width: w,
								marginTop: 15,
							}}>
							<Image
								source={dd.data}
								style={{
									width: w,
									height: w * (dd.height / dd.width),
									maxHeight:
										window.innerWidth *
										0.8 *
										(dd.height / dd.width),
									maxWidth: window.innerWidth * 0.8,
									resizeMode: "cover",
								}}
							/>
						</View>
					) : dd.type === "h1" ? (
						<View
							style={
								{
									// flex: 1,
									// paddingTop:
									// 	d.body.length > 1 ? 5 : pdTop,
								}
							}>
							<Text
								style={{
									fontSize: h1,
									color: APP_COLOURS.TEXTCOLOR,
									// marginBottom: h2,
									// fontFamily: "Montserrat",
								}}>
								<Font family='Montserrat' weight={800}>
									{dd.text}
								</Font>
							</Text>
							<Font family='Montserrat' weight={500}>
								<Text
									style={{
										fontSize: h2,
										marginBottom: h2,
										flexDirection: "row",
										opacity: 0.6,
										color: "#000",
										fontFamily: "Montserrat",
										textAlign: "justify",
									}}>
									<Font family='Montserrat' weight={500}>
										{dd.subtitle}
									</Font>
								</Text>
							</Font>
						</View>
					) : dd.type === "h2" ? (
						<View
							style={
								{
									// flex: 1,
									// marginBottom: h2,
									// paddingLeft: "5%",
									// paddingRight: "5%",
									// paddingTop:
									// 	d.body.length > 1 ? 5 : pdTop,
								}
							}>
							<Text
								style={{
									fontSize: h2,
									color: APP_COLOURS.TEXTCOLOR,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={600}>
									{dd.text}
								</Font>
							</Text>
						</View>
					) : dd.type === "pdf" ? (
						<View style={{}}>
							{/* <Document file={dd.data}>
								<Page pageNumber={1} />
							</Document> */}
						</View>
					) : dd.type === "p" ? (
						<Text
							style={{
								// flex: 1,
								// width: "100%",
								paddingBottom: 15,
								fontSize: pp,
								color: APP_COLOURS.TEXTCOLOR,
								marginBottom: pp,
								fontFamily: "Montserrat",
								textAlign: "justify",
								fontWeight: "300",
								paddingRight: "5%",
							}}>
							{dd.text}
						</Text>
					) : dd.type === "li" ? (
						<View
							style={
								{
									// flex: 1,
									// paddingLeft: "5%",
									// paddingRight: "5%",
								}
							}>
							<Text
								style={{
									fontSize: pp,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 5,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={500}>
									{dd.text}
								</Font>
							</Text>
							<Text
								style={{
									fontSize: pp,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 15,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									{dd.subtitle}
								</Font>
							</Text>
							{dd.items.map((ddd) => (
								<>
									<Text
										style={{
											fontSize: pp,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 5,
											flexDirection: "row",
											// paddingLeft: 15,
										}}>
										<Font family='Montserrat' weight={500}>
											- {ddd.text}
										</Font>
									</Text>
									<Text
										style={{
											fontSize: pp,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: pp,
											flexDirection: "row",
											paddingLeft: pp,
										}}>
										<Font family='Montserrat' weight={300}>
											{ddd.subtitle}
										</Font>
									</Text>
								</>
							))}
						</View>
					) : dd.type === "slide" ? (
						<View
							style={{
								// flex: 1,
								paddingLeft: "5%",
							}}>
							<ImageLoop
								steps={dd.items}
								h1={h1}
								h2={h2}
								pp={pp}
								stepTitle={(title) =>
									this.setState({
										stepTitle: title,
									})
								}
							/>
						</View>
					) : null}
				</Fade>
			</View>
		);
	};

	componentDidMount() {
		this.setState({
			...this.props.data,
		});
		console.log("componentDidMount");
		let accessToken = sessionStorage.getItem("accessToken");

		console.log("Approved User", accessToken);
		if (accessToken !== null) {
			// this.props.history.push("/dashboard/Home");
		} else {
			sessionStorage.clear();
			this.setState({
				loading: false,
			});
		}

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}
}
