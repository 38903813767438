import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import { InView } from "react-intersection-observer";
import logo_tetrice from "./assets/logo_tetrice.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import bg1 from "./assets/bg_tech.png";
import { APP_COLOURS } from "./APP_VARS";

import "./resizeScale.scss";
import "./hoverEffect.scss";

export default class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				inView: true,
				showObjects: true,
				showPhone: true,
			});
		}, 500);
	}

	render() {
		let contain =
			window.innerWidth < window.innerHeight
				? window.innerWidth * 0.8
				: window.innerHeight * 0.45;

		let maxContain = contain > 600 ? 600 : contain;

		let ar = 636 / 545;

		let h = window.innerHeight;
		let w = window.innerWidth;

		let { h1, h2, pp } = this.props;

		if (!this.state.inView) {
			return (
				<View
					style={{
						width: window.innerWidth,
						height: window.innerHeight,
						backgroundColor: "#1F2229",
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						top: 0,
						zIndex: 999,
					}}>
					<Image
						source={logo_tetrice}
						style={{
							width: 200,
							height: 100,
							resizeMode: "contain",
						}}
					/>
				</View>
			);
		} else
			return (
				<View
					style={{
						// flex: 1,
						width: window.innerWidth,
						// height: window.innerHeight * 0.85,
						// paddingLeft: "15%",
						// backgroundColor: "#171A20",
						// padding: "5%",
						// minHeight: 750,
					}}>
					<View
						style={{
							width: window.innerWidth,
							flexDirection: "row",
							flexWrap: "wrap",
							height: window.innerHeight * 0.85,
							backgroundColor: "#171A20",
							padding: "10%",
							maxHeight: 900,
							overflow: "hidden",
						}}>
						<View
							style={{
								// flex: 1,
								position: "absolute",
								right: -50,
								bottom: "-5%",
								width: maxContain * ar,
								height: maxContain,
								// backgroundColor: "red",
								// opacity: 0.3,
								// maxHeight: "60%",
							}}>
							<Image
								// source={bg1}
								source={require("./assets/shoutImage.png")}
								style={{
									width: maxContain * ar,
									height: maxContain,
									maxHeight: maxContain,
									maxWidth: maxContain * ar,
									resizeMode: "contain",
								}}
							/>
						</View>
						<View
							style={{
								flex: 1,
								maxWidth: 500,
								opacity: 0.9,
							}}>
							<Image
								source={logo_tetrice}
								style={{
									width: "50%",
									maxWidth: 350,
									height: 100,
									resizeMode: "contain",
								}}
							/>
							<Fade cascade left>
								<Text
									style={{
										fontSize: h1,
										color: "#FFF",
									}}>
									<Font family='Poppins' weight={800}>
										PR & Community Management
									</Font>
								</Text>

								<Text
									style={{
										fontSize: h2,
										color: "#FFF",
									}}>
									<Font family='Poppins' weight={400}>
										Proposal Prepared for Revlon & Elizabeth
										Arden
									</Font>
								</Text>
							</Fade>
						</View>
					</View>
					<View
						style={{
							flex: 1,
							// padding: 40,
							// paddingLeft: "15%",
						}}>
						{/* <View
							style={{
								// flex: 1,
								// position: "absolute",
								// top: "5%",
								// left: "2%",
								marginTop: 90,
								// paddingLeft: 30,
								marginBottom: 20,
							}}>
							<Image
								source={logo_tetrice}
								style={{
									width: "70%",
									maxWidth: 280,
									height: 110,
									resizeMode: "contain",
								}}
							/>
						</View> */}
					</View>
					{/* <View
						style={{
							padding: 30,
							width: "100%",
							paddingBottom: 10,
							// alignItems: "center",
							marginTop: 200,
							// paddingLeft: "15%",
						}}>
						<Text
							style={{
								// width: 110,
								fontSize: 19,
								color: "#FFF",
							}}>
							<Font family='Poppins' weight={800}>
								tetrice cosulting
							</Font>
						</Text>

						<Image
							source={require("./assets/services.png")}
							style={{
								width: 360,
								height: 120,
								resizeMode: "contain",
								// position: "absolute",
								// left: -10,
							}}
						/>
					</View>
					<View
						style={{
							padding: 30,
							width: "100%",
							flexDirection: "row",
							justifyContent: "flex-end",
							paddingRight: 30,
							paddingLeft: 30,
							paddingBottom: 10,
							alignItems: "center",
						}}>
						<Image
							source={require("./assets/infod.png")}
							style={{
								width: 240,
								height: 120,
								resizeMode: "contain",
								// position: "absolute",
								// left: -10,
							}}
						/>
					</View> */}
				</View>
			);
	}
}
