import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/72675-social-media.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		let mw = 1200;
		let h = this.props.screenHeight;
		let w = this.props.screenWidth > mw ? mw : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		let fontS = {
			fontSize: 20,
			color: APP_COLOURS.TEXTCOLOR,
			fontFamily: "Montserrat",
			textAlign: "justify",
			fontWeight: "300",
		};

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
					// paddingBottom: "10%",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							width: w,
							// height: h,
							height: "auto",
							padding: 20,
							// paddingTop: "10%",
							zIndex: 1,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Fade>
							<div
							// className={
							// 	this.state.inView ? `reveal` : `fadeOut`
							// }
							>
								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h * 0.2,
										width: window.innerWidth * 0.8,
										maxWidth: mw,
									}}>
									<View
										style={{
											flex: 1,
											// padding: 20,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													fontSize: 45,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={600}>
													Building a Luxurious
													Community
												</Font>
											</Text>
											<Text
												style={{
													fontSize: 22,

													marginBottom: 5,
													flexDirection: "row",
													opacity: 0.4,
													color: "#000",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													Cultivating connections that
													last a lifetime.
												</Font>
											</Text>
										</View>
									</View>
									<View
										style={{
											flex: 1,
											padding: 20,
										}}>
										<Lottie
											options={defaultOptions}
											height={h * 0.35}
											width={h * 0.45}
										/>
									</View>
								</View>

								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										width: window.innerWidth * 0.8,
										maxWidth: mw,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={[
												{
													fontSize: 22,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												},
												fontS,
											]}>
											Community engagement plays a vital
											role in fostering customer
											relationships and loyalty for Revlon
											& Elizabeth Arden. By connecting
											with our current and new customers
											on a deeper level, we can create a
											sense of belonging and exclusivity
											that elevates our brand and
											encourages long-term loyalty.
										</Text>

										<Text
											style={{
												fontSize: 22,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 5,
												flexDirection: "row",
												marginVertical: 20,
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Online Community Initiatives:
											</Font>
										</Text>

										{[
											{
												name: "Exclusive Members-Only Forum",
												description:
													"Create a members-only platform where customers can access insider tips, expert advice, product sneak peeks, and exclusive promotions. Encourage discussions and sharing of personal experiences related to Revlon & Elizabeth Arden products.",
											},
											{
												name: "Social Media Contests and Giveaways",
												description:
													"Organize regular contests and giveaways on social media platforms, inviting followers to share their favorite looks, tips, or personal stories involving Revlon & Elizabeth Arden products. Reward the most creative and engaging entries.",
											},
											{
												name: "Live Q&A Sessions and Tutorials",
												description:
													"Host live sessions featuring beauty experts, brand ambassadors, or influencers. Engage the audience with tutorials, expert advice, and real-time Q&A sessions, creating a sense of personal connection with the brand.",
											},
										].map((d) => (
											<>
												<Text
													style={[
														fontS,
														{
															marginBottom: 5,
															fontWeight: "400",
														},
													]}>
													{d.name}
												</Text>
												<Text
													style={[
														{
															marginBottom: 15,
														},
														fontS,
													]}>
													{d.description}
												</Text>
											</>
										))}

										<Text
											style={{
												fontSize: 22,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 5,
												flexDirection: "row",
												marginVertical: 20,
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Offline Community Initiatives:
											</Font>
										</Text>

										{[
											{
												name: "In-Store Beauty Workshops",
												description:
													"Organize exclusive workshops at retail locations where customers can learn new makeup techniques, explore the latest products, and receive personalized consultations from beauty experts.",
											},
											{
												name: "VIP Product Launch Events",
												description:
													"Invite loyal customers and influencers to attend special events celebrating new product launches. Provide an immersive experience that includes product demonstrations, expert consultations, and unique photo opportunities.",
											},
											{
												name: "Community Outreach Programs",
												description:
													"Partner with local charities, non-profit organizations, or beauty schools to offer beauty workshops, makeup donations, or other initiatives that contribute to the well-being of the community and align with Revlon & Elizabeth Arden's values.",
											},
										].map((d) => (
											<>
												<Text
													style={[
														fontS,
														{
															marginBottom: 5,
															fontWeight: "300",
														},
													]}>
													{d.name}
												</Text>
												<Text
													style={[
														{
															marginBottom: 15,
														},
														fontS,
													]}>
													{d.description}
												</Text>
											</>
										))}
									</View>
								</View>
							</div>
						</Fade>
					</View>

					{/* <PageFooter /> */}
				</View>
			</div>
		);
	}
}
