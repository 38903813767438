import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Font from "react-font";
import Fade from "react-reveal/Fade";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/76961-weather-alert-notification.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";

import { InView } from "react-intersection-observer";
import logo_tetrice from "./assets/logo_tetrice.png";

import moment from "moment";
import ImageLoop from "./ImageLoop";

export default class ProposalDigitalStrategy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inView: false,
			clientLogo: "",
			documentTitle: "Digital Strategy Proposal",
			clientName: "Mitchum - REVLON Personal care",

			pages: [
				{
					body: [
						{
							type: "h1",
							text: "Introduction",
							subtitle: ``,
							subParagraph:
								"The following digital strategy proposal outlines a comprehensive 360-degree approach for MITCHUM that sells its products in-store, online, and through several retailers on their in-store and online platforms. This strategy aims to elevate brand presence, drive engagement, and increase sales across all channels.",
							ul: [],
							ol: [],
						},
					],
				},
				{
					body: [
						{
							type: "h1",
							text: "Index",
							subtitle: ``,
							subParagraph: "",
							ul: [
								{
									text: "Digital Strategy Roadmap",
									subtitle: `The 360 overview of digital landscape`,
								},
								{
									text: "Digital Channels:",
									subtitle: `The 360 overview of digital landscape`,
								},
								{
									text: "Research",
									subtitle: ``,
								},
								{
									text: "Brand position",
									subtitle: `The 360 overview of brand position`,
								},
								{
									text: "Social media platforms & Strategy",
									subtitle: `Each platform and its own unique strategy`,
								},

								{
									text: "Website",
									subtitle: `The new website build, and content schedule for 2024/25`,
								},

								{
									text: "SEO",
									subtitle: `SEO report 2024, SEO strategy & retainer`,
								},
								{
									text: "Targeted campaigns",
									subtitle: `Paid platform strategy`,
								},
								{
									text: "Ecomm Platform strategies",
									subtitle: `Ecomm brandf pages, ecomm listings & optomising listings, Relooked at each brand page, analysing user journey and optimising the brand page layout and content to drive sales and improve brand’s digital presence. Create a space for the brand online, like there would be in store.`,
								},
								{
									text: "Digital Merchandising & Support strategy: Listings",
									subtitle: `Strategic planning to grow digital per retailer, enhancing KAM commitments & exploring further opportunities by:`,
								},
							],
							ol: [],
						},
					],
				},

				// {
				// 	body: [
				// 		{
				// 			type: "img",
				// 			data: require("./Screenshot 2024-07-01 at 18.54.11.png"),
				// 			width: 1100,
				// 			height: 630,
				// 			resizeMode: "contain",
				// 		},
				// 	],
				// },

				{
					body: [
						{
							type: "img",
							data: require("./5d9a3d82-bd69-4755-99ad-382b31a7ebb2.webp"),
							width: 1100,
							height: 630,
							resizeMode: "contain",
						},
					],
				},

				{
					body: [
						{
							type: "h1",
							text: "Digital roadmap",
							subtitle: `A well-defined digital roadmap is essential for guiding a luxury brand through the complex landscape of digital marketing. This roadmap outlines the key steps necessary for creating a comprehensive digital strategy.`,
							subParagraph: "",
							ol: [
								{
									text: "Research",
									subtitle: ``,
								},
								{
									text: "Establish objectives",
									subtitle: ``,
								},
								{
									text: "Define your brand",
									subtitle: ``,
								},
								{
									text: "Know your Target Audience, build personas",
									subtitle: ``,
								},
								{
									text: "Identify competitor",
									subtitle: ``,
								},
								{
									text: "Platform selection",
									subtitle: ``,
								},
								{
									text: "Content creation process - social media",
									subtitle: ``,
								},
								{
									text: "Content marketing process - blogs",
									subtitle: ``,
								},
								{
									text: "Email marketing process",
									subtitle: ``,
								},
								{
									text: "Paid media process & budget",
									subtitle: ``,
								},
								{
									text: "Measuring success",
									subtitle: ``,
								},
								{
									text: "Analyse and iterate for improved outcomes.",
									subtitle: ``,
								},
							],
							ul: [],
						},
					],
				},

				{
					body: [
						{
							type: "h1",
							text: "Digital Channels",
							subtitle: `Channels Selection:`,
							subParagraph: "",
							ul: [
								{
									text: "Brand Website (Online Store)",
									subtitle: ``,
								},
								{
									text: "Social Media",
									subtitle: ``,
								},
								{
									text: "Email Marketing",
									subtitle: ``,
								},
								{
									text: "Content Marketing",
									subtitle: ``,
								},
								{
									text: "Influencer Partnerships",
									subtitle: ``,
								},
								{
									text: "Search Engine Optimization (SEO)",
									subtitle: ``,
								},
								{
									text: "Pay-Per-Click Advertising (PPC)",
									subtitle: ``,
								},
								{
									text: "Retailer Platforms (In-store & Online)",
									subtitle: ``,
								},
								{
									text: "Mobile App",
									subtitle: ``,
								},
								{
									text: "Customer Relationship Management (CRM)",
									subtitle: ``,
								},
								{
									text: "Analytics and Reporting",
									subtitle: ``,
								},
							],
							ol: [],
						},
					],
				},

				{
					body: [
						{
							type: "h1",
							text: "Research: The growth of digital 2024/25",
							items: [],
						},
					],
				},
				{
					body: [
						{
							type: "p",
							text: "e-Commerce, and other digital sales channels continue to grow post COVID-19. It’s important to adapt and improve digital brand presence as forward-thinking businesses adopt an omnichannel approach.",
							subtitle: "",
							items: [],
						},
					],
				},

				{
					body: [
						{
							type: "h2",
							text: "Revenue",
							items: [],
						},
						{
							type: "img",
							data: require("./Screenshot 2024-07-01 at 19.04.13.png"),
							width: 650,
							height: 250,
							resizeMode: "contain",
						},
					],
				},
				{
					body: [
						{
							type: "h2",
							text: "ARPU (Average Revenue per User)",
							items: [],
						},
						{
							type: "img",
							data: require("./Screenshot 2024-07-01 at 19.04.13.png"),
							width: 650,
							height: 250,
							resizeMode: "contain",
						},
					],
				},

				{
					body: [
						{
							type: "h1",
							text: "Channel-Specific Strategies",
							subtitle: `Brand Website (Online Store):`,
							subParagraph: "",
							ul: [],
							ol: [],
						},
					],
				},
				{
					body: [
						{
							type: "ul",
							text: "Objectives:",
							subtitle: ``,
							items: [
								{
									text: "Enhance user experience,",
									subtitle: ``,
								},
								{
									text: "Increase online sales,",
									subtitle: ``,
								},
								{
									text: "Improve conversion rates",
									subtitle: ``,
								},
							],
							ol: [],
						},
					],
				},
				{
					body: [
						{
							type: "ul",
							text: "Strategies:",
							subtitle: ``,
							items: [
								{
									text: "Website Design:",
									subtitle: `Ensure a luxurious, user-friendly, and responsive design.`,
								},
								{
									text: "E-commerce Optimization: ",
									subtitle: `Simplify navigation, improve product search functionality, and offer secure, multiple payment options.`,
								},
								{
									text: "Personalization:",
									subtitle: `Use AI-driven recommendations based on browsing history and preferences.`,
								},
								{
									text: "Content:",
									subtitle: `High-quality images, detailed product descriptions, customer reviews, and videos.`,
								},
								{
									text: "Speed Optimization:",
									subtitle: `Ensure fast loading times to reduce bounce rates.`,
								},
							],
							ol: [],
						},
					],
				},

				{
					body: [
						{
							type: "h1",
							text: "Social Media: Channel-Specific Strategies",
							subtitle: ``,
							subParagraph: "",
							ul: [],
							ol: [],
						},
					],
				},
				{
					body: [
						{
							type: "ul",
							text: "Objectives:",
							subtitle: ``,
							items: [
								{
									text: "Build brand awareness",
									subtitle: ``,
								},
								{
									text: "Engage with customers",
									subtitle: ``,
								},
								{
									text: "Drive traffic to the website",
									subtitle: ``,
								},
								{
									text: "Track and monitor click-throughs",
									subtitle: ``,
								},
							],
							ol: [],
						},
					],
				},

				{
					body: [
						{
							type: "ul",
							text: "Strategies:",
							subtitle: ``,
							items: [
								{
									text: "Platforms:",
									subtitle: `Focus on Instagram, Facebook, TikTok, and Youtube.`,
								},
								{
									text: "Content Calendar:",
									subtitle: `Regularly post a mix of product showcases, behind-the-scenes, user-generated content, and lifestyle images.`,
								},
								{
									text: "Influencer Collaborations",
									subtitle: `Partner with influencers who align with the brand’s image.`,
								},
								{
									text: "Advertising: On platform Boosting",
									subtitle: `Use targeted ads to reach potential customers.`,
								},
								{
									text: "Engagement:",
									subtitle: `Actively respond to comments, messages, and mentions.`,
								},
							],
							ol: [],
						},
					],
				},

				{
					body: [
						{
							type: "h1",
							text: "Email Marketing:",
							subtitle: ``,
							subParagraph: "",
							ul: [],
							ol: [],
						},
					],
				},
				{
					body: [
						{
							type: "ul",
							text: "Objectives:",
							subtitle: ``,
							items: [
								{
									text: "Nurture customer relationships",
									subtitle: ``,
								},
								{
									text: "Increase repeat purchases",
									subtitle: ``,
								},
								{
									text: "Promote sales and new arrivals",
									subtitle: ``,
								},
							],
							ol: [],
						},
					],
				},
				{
					body: [
						{
							type: "ul",
							text: "Strategies:",
							subtitle: ``,
							items: [
								{
									text: "Segmentation:",
									subtitle: `Create targeted email lists based on customer behavior and preferences.`,
								},
								{
									text: "Personalization:",
									subtitle: `Personalize emails with the recipient’s name, product recommendations, and exclusive offers.`,
								},
								{
									text: "Automated Campaigns:",
									subtitle: `Set up automated workflows for welcome emails, abandoned cart reminders, and post-purchase follow-ups.`,
								},
								{
									text: "Design:",
									subtitle: `Use visually appealing templates that reflect the brand’s luxury image.`,
								},
								{
									text: "Analytics:",
									subtitle: `Track open rates, click-through rates, and conversions to optimize future campaigns`,
								},
							],
							ol: [],
						},
					],
				},

				{
					body: [
						{
							type: "h1",
							text: "Content Marketing:",
							subtitle: ``,
							subParagraph: "",
							ul: [],
							ol: [],
						},
					],
				},

				{
					body: [
						{
							type: "ul",
							text: "Objectives:",
							subtitle: ``,
							items: [
								{
									text: "Establish brand authority",
									subtitle: ``,
								},
								{
									text: "Improve SEO",
									subtitle: ``,
								},
								{
									text: "Engage with the audience",
									subtitle: ``,
								},
							],
							ol: [],
						},
					],
				},

				{
					body: [
						{
							type: "ul",
							text: "Strategies:",
							subtitle: ``,
							items: [
								{
									text: "Blog:",
									subtitle: `Publish regular blog posts on fashion trends, product care tips, and brand stories.`,
								},
								{
									text: "Video Content:",
									subtitle: `Create high-quality videos for product launches, tutorials, and behind-the-scenes looks.`,
								},
								{
									text: "SEO Optimization:",
									subtitle: `Optimize all content for search engines with relevant keywords and meta descriptions.`,
								},
								{
									text: "Collaborations:",
									subtitle: `Work with industry experts and guest bloggers.`,
								},
							],
							ol: [],
						},
					],
				},

				// {
				// 	body: [
				// 		{
				// 			type: "h1",
				// 			text: `A New Era in Fitness`,
				// 			subtitle: `Mitchum.co.za:`,
				// 			subParagraph:
				// 				"This presentation outlines a website for the Mitchum activewear brand, designed to captivate investors and marketing partners. The website will be a dynamic platform, seamlessly blending personal care, fashion, and fitness.",
				// 			ul: [],
				// 			ol: [],
				// 		},
				// 	],
				// },

				// {
				// 	body: [
				// 		{
				// 			type: "h1",
				// 			text: "A Unique Brand Proposition",
				// 			subtitle: ``,
				// 			subParagraph: "",
				// 			ul: [
				// 				{
				// 					text: "Position 1",
				// 					subtitle: ``,
				// 				},
				// 				{
				// 					text: "Position 1",
				// 					subtitle: ``,
				// 				},
				// 				{
				// 					text: "Position 1",
				// 					subtitle: ``,
				// 				},
				// 			],
				// 			ol: [],
				// 		},
				// 	],
				// },

				// {
				// 	body: [
				// 		{
				// 			type: "img",
				// 			data: "https://www.mitchum.com/wp-content/uploads/2022/06/Mitchum_US_Homepage_Banner2_2560x775.jpg",
				// 			width: "100%",
				// 			height: 400,
				// resizeMode:	"cover"
				// 		},
				// 	],
				// },

				{
					body: [
						{
							type: "h1",
							text: `Website Development & Design`,
							subtitle: `User Interface (UI)`,
							subParagraph: "",
							ul: [],
							ol: [],
						},
						{
							type: "ul",
							text: "",
							subtitle: ``,
							items: [
								{
									text: "User Interface (UI)",
									subtitle: `Focus on user-friendliness, clean layout, intuitive navigation, and visually appealing design elements.`,
								},
								{
									text: "User Experience (UX)",
									subtitle: `Create a seamless and engaging experience, prioritizing speed, responsiveness, and accessibility.`,
								},
								{
									text: "Content Management System (CMS)",
									subtitle: `Utilize a robust CMS for easy content updates, product management, and website maintenance.`,
								},
								{
									text: "Security & Hosting",
									subtitle: `Ensure website security and choose a reliable hosting provider for optimal performance and uptime.`,
								},
								{
									text: "Emailer & Marketing campaigns",
									subtitle: `On platform emailer & marketing news letter and notices`,
								},
							],
						},
					],
				},

				{
					body: [
						{
							type: "h1",
							text: `Call to Action`,
							subtitle: `Mitchum.co.za:`,
							subParagraph: "",
							ul: [
								{
									text: "Join Us",
									subtitle: `We invite you to join us in revolutionizing the activewear industry. Together, we can create a brand that empowers individuals to embrace their fitness journey with style and confidence.`,
								},
								{
									text: "Investment Opportunities",
									subtitle: `We are seeking investors who share our vision for a sustainable and innovative fragrance activewear brand. Your investment will be instrumental in our growth and success.`,
								},
								{
									text: "Marketing Partnerships",
									subtitle: `We are eager to collaborate with marketing partners who can help us reach a wider audience and effectively promote our brand.`,
								},
							],
							ol: [],
						},
					],
				},

				{
					body: [
						{
							type: "h1",
							text: `Landing Page Optimization`,
							subtitle: ``,
							subParagraph:
								"This presentation outlines a website for the Mitchum activewear brand, designed to captivate investors and marketing partners. The website will be a dynamic platform, seamlessly blending personal care, fashion, and fitness.",
						},
					],
				},
				{
					body: [
						{
							type: "h1",
							text: "Website Functionality & Features",
							subtitle: `Lead customers to our sites`,
							items: [
								{
									text: "Skin Diagnostics tool with in-store QR Code.",
									subtitle: `Lead customers to our skindiagnostics site where they will be prompted to fill out a short questionaire. `,
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "ul",
							text: "",
							subtitle: ``,
							items: [
								{
									text: "Online Store",
									subtitle: `Our website will offer a user-friendly online store to purchase our fragrance activewear, allowing customers to easily browse and order.`,
								},
								{
									text: "Product Detail Pages",
									subtitle: `Each product will have a detailed page with descriptions, fragrance notes, size charts, and customer reviews, ensuring transparency.`,
								},
								{
									text: "Blog & Content",
									subtitle: `We will create engaging blog content about fitness, wellness, and fragrance, attracting readers and building a community.`,
								},
								{
									text: "Social Media Integration",
									subtitle: `Our website will seamlessly integrate with social media platforms, encouraging user interaction and brand visibility.`,
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "h1",
							text: "Strategic Marketing & SEO",
							subtitle: `Lead customers to our sites`,
							items: [
								{
									text: "Skin Diagnostics tool with in-store QR Code.",
									subtitle: `Lead customers to our skindiagnostics site where they will be prompted to fill out a short questionaire. `,
								},
							],
						},
						{
							type: "ol",
							text: "",
							subtitle: ``,
							items: [
								{
									text: "Targeted Keyword Research",
									subtitle: `We will conduct thorough keyword research to identify high-volume, relevant keywords for optimizing website content.`,
								},
								{
									text: "Content Optimization",
									subtitle: `All website content, including product descriptions and blog posts, will be meticulously optimized for search engines.`,
								},
								{
									text: "Social Media Marketing.",
									subtitle: `We will leverage social media platforms to promote our brand, engage with users, and drive traffic to the website. `,
								},
								{
									text: "Influencer Marketing",
									subtitle: `Collaborations with fitness and lifestyle influencers will increase brand awareness and reach a wider audience. `,
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "h1",
							text: "Financial Projections & ROI",
							subtitle: ``,
							items: [],
						},
						{
							type: "ol",
							text: "",
							subtitle: ``,
							items: [
								{
									text: "Initial Investment",
									subtitle: `Our website development and initial marketing efforts require an investment of [Insert amount] to establish a strong online presence.`,
								},
								{
									text: "Projected Revenue",
									subtitle: `We project [Insert amount] in revenue within the first year, based on market research and projected sales growth.`,
								},
								{
									text: "Social Media Marketing.",
									subtitle: `We will leverage social media platforms to promote our brand, engage with users, and drive traffic to the website. `,
								},
								{
									text: "Return on Investment (ROI)",
									subtitle: `With effective marketing and strong customer acquisition, we anticipate a high ROI within [Insert timeframe], demonstrating the potential for significant returns. `,
								},
							],
						},
					],
				},

				{
					body: [
						{
							type: "img",
							data: require("./18798bad-1a09-4db7-af49-d848c4d1deb1.webp"),
							width: 1100,
							height: 650,
							resizeMode: "contain",
						},
					],
				},

				{
					body: [
						{
							type: "h1",
							text: `Omnichannel Strategy:`,
							subtitle: `Many shoppers do not just shop in-store or online. Many use multiple channels throughout their journey. The integration of these touchpoints for a seamless customer journey is what omnichannel is all about.`,
							subParagraph: "",
						},
						{
							type: "ol",
							text: "",
							subtitle: ``,
							items: [
								{
									text: "",
									subtitle:
										"Digital Merchanidising: The representation of the brand and the products across digital platforms, like products would be displayed on beautiful shelves in physical stores, digital shelves need to be just as attractive and delightful.",
								},
								{
									text: "",
									subtitle:
										"It's all about a dynamic, personal shopping experience with user journey top of mind.",
								},
								{
									text: "",
									subtitle:
										"Digital merchandising allows us to display our products in a virtual shelf aka online listing",
								},
								{
									text: "",
									subtitle:
										"The digital shelf is where consumers go to research and review, discover and select the products",
								},
								{
									text: "",
									subtitle:
										"Our competitors who dominate space in bricks-and-mortar, don’t necessarily have the same advantage online! Let’s plan for a digital take over to dominate more online!",
								},
							],
						},
					],
				},

				{
					body: [
						{
							type: "img",
							data: require("./88c2f599-b98c-4443-96d0-c34a976b33e5.webp"),
							width: 1100,
							height: 650,
							resizeMode: "contain",
						},
					],
				},

				{
					body: [
						{
							type: "h1",
							text: "Support Focuses",
							subtitle: `Strategic planning to grow digital per retailer, enhancing KAM commitments & exploring further opportunities by:`,
							items: [],
						},
					],
				},

				{
					body: [
						{
							type: "ol",
							text: "A) Driving traffic, customer acquisition, customer journey & conversions through:",
							subtitle: ``,
							items: [
								{
									text: "",
									subtitle:
										"Social linking & identifying social media plans to align with retailers strategic periods",
								},
								{
									text: "",
									subtitle:
										"work closely with PR to ensure always-on social content calendars are aligned and shared monthly with retailers to drive awareness, provide value, prompt engagement and drive conversion",
								},
								{
									text: "",
									subtitle:
										"Sampling & online exclusive opportunities",
								},
								{
									text: "",
									subtitle:
										"Ensuring all websites which list Revlon brands (RCC, PC, EA), brand pages, product listing/ PDP, promo blocks etc are maintained",
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "ul",
							text: "B) Maximize NPD's, promo, re-support and digital shelf life to optimize UX (which includes NPD listing's (copy & images – primary and secondary)",
							subtitle: ``,
							items: [
								{
									text: "",
									subtitle:
										"Social linking & identifying social media plans to align with retailers strategic periods",
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "ul",
							text: "C) Competitor & historical analysis",
							subtitle: ``,
							items: [
								{
									text: "",
									subtitle:
										"Tracking e-comm performance against set budget/target and working with KAM’s with proposed plans.",
								},
								{
									text: "",
									subtitle:
										"Brand consistency/brand visibility - briefing artwork requests. Ensuring Copy is aligned to company & legal guidelines & images are of high standards.Digital GTM Kits are prepared, aligned and shared (with the above plans)",
								},
								{
									text: "",
									subtitle:
										"Beauty Tech Tools – maintenance, management and planning",
								},
							],
						},
					],
				},

				{
					body: [
						{
							type: "img",
							data: require("./96426a81-54c2-429d-b4cd-f1a5641b69f0.webp"),
							width: 1100,
							height: 650,
							resizeMode: "contain",
						},
					],
				},

				{
					body: [
						{
							type: "h1",
							text: "Rules of Engagement",
							subtitle: ``,
							items: [],
						},
						{
							type: "ol",
							text: "Goal:",
							subtitle: `An essential part of the commerce journey is how our product exist on our Digital Shelves!`,
							items: [
								{
									text: "",
									subtitle: "No Digital shelf NO e-Commerce!",
								},
								{
									text: "",
									subtitle: `Engaging Product pages driving consistent messages`,
								},
								{
									text: "",
									subtitle:
										"Understanding Customer Journey ~ Path to Purchase! ",
								},
								{
									text: "",
									subtitle:
										"Buying journey for a customer likely starts with a search bar – Product description is fundamental. How can we own the search functionality in key moments? ",
								},
								{
									text: "",
									subtitle:
										"Our competitors who dominate space in bricks-and-mortar, don’t necessarily have the same advantage online! Let’s plan for a digital take over to dominate more online!",
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "h1",
							text: `Digital Shelf:`,
							subtitle: ``,
							subParagraph: "",
						},
						{
							type: "ol",
							text: "",
							subtitle: ``,
							items: [
								{
									text: "",
									subtitle:
										"Digital Merchanidising: The representation of the brand and the products across digital platforms, like products would be displayed on beautiful shelves in physical stores, digital shelves need to be just as attractive and delightful.",
								},
								{
									text: "",
									subtitle:
										"It's all about a dynamic, personal shopping experience with user journey top of mind.",
								},
								{
									text: "",
									subtitle:
										"Digital merchandising allows us to display our products in a virtual shelf aka online listing",
								},
								{
									text: "",
									subtitle:
										"The digital shelf is where consumers go to research and review, discover and select the products",
								},
								{
									text: "",
									subtitle:
										"Our competitors who dominate space in bricks-and-mortar, don’t necessarily have the same advantage online! Let’s plan for a digital take over to dominate more online!",
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "ol",
							text: "Digital Shelf:",
							subtitle: ``,
							items: [
								{
									text: "",
									subtitle:
										"Assets/Images/Creatives which support NPD, Promo’s, Range Extensions,Reno’s, key moments",
								},
								{
									text: "",
									subtitle:
										"Kit usually consists of Webpage Banner, Social Posts specifically sized per retailer",
								},
							],
						},
					],
				},

				{
					body: [
						{
							type: "ol",
							text: "Masterdata:",
							subtitle: ``,
							items: [
								{
									text: "",
									subtitle:
										"The new single source of truth document that contains all product content for all retailers",
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "points",
							text: "",
							subtitle: "Audit & Fixes Digital Shelf Clean-Up",
							items: [
								{
									text: "1",
									subtitle:
										"Audit & Fixes Digital Shelf Clean-Up",
								},
								{
									text: "2",
									subtitle:
										"Sensorial Marketing Secondary Images",
								},
								{
									text: "3",
									subtitle:
										"Remapping | Branding Brand Pages",
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "h2",
							text: "Retailer Strategy Principles",
							subtitle: ``,
							items: [],
						},
					],
				},

				{
					body: [
						{
							type: "img",
							data: require("./Screenshot 2024-07-01 at 18.46.54.png"),
							width: "100%",
							height: 350,
							resizeMode: "contain",
						},
					],
				},

				{
					body: [
						{
							type: "ul",
							text: "Responsibilities:",
							subtitle: `NOTE! Uploading into retailer specific, portals does not fall under digital support.`,
							items: [
								{
									text: "Digital team",
									subtitle: "Online Listing (Info & Images)",
								},
								{
									text: "Digital team",
									subtitle: "Communication with retailers",
								},
								{
									text: "Digital team",
									subtitle:
										"Digital Kits Sharing | Brand Page Updates",
								},
								{
									text: "Digital team",
									subtitle:
										"The digital shelf is where consumers go to research and review, discover and select the products",
								},
								{
									text: "Digital team",
									subtitle: "Go-Live Share Results!",
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "img",
							data: require("./Screenshot 2024-07-01 at 18.54.11.png"),
							width: "100%",
							height: 200,
							resizeMode: "contain",
						},
					],
				},
			],

			costEstimate: [],
		};
	}

	pages = (i, d, h1, h2, pp, w) => {};

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = window.innerHeight;
		let w = window.innerWidth > 1100 ? 1100 : window.innerWidth;

		let ce = this.state.costEstimate;

		let fs = window.innerWidth * 0.07;
		let maxFont = 45;
		let h1 = fs < maxFont ? fs : maxFont;
		let h2 = h1 * 0.65;
		let h3 = h1 * 0.55;
		let pp = h1 * 0.45;

		return (
			<View
				style={{
					// overflow: "hidden",
					// backgroundColor: "#EDF1F4",
					backgroundColor: "#0C0C0C",
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
					overflow: "hidden",
					// padding: 10,
					paddingBottom: "5%",
				}}>
				<View
					style={{
						width: w,
						alignItems: "center",
					}}>
					<LandingPage {...this.state} h1={h1} h2={h2} pp={pp} />
					{this.state.pages.map((d, i) => (
						<ProposalPages
							d={d}
							i={i}
							{...this.state}
							h1={h1}
							h2={h2}
							h3={h3}
							pp={pp}
						/>
					))}
				</View>
			</View>
		);
	}
}

class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false, hover: false };
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				inView: true,
				showObjects: true,
				showPhone: true,
			});
		}, 500);
	}

	render() {
		let contain =
			window.innerWidth < window.innerHeight
				? window.innerWidth * 0.45
				: window.innerHeight * 0.3;

		let maxContain = contain > 350 ? 350 : contain;

		let ar = 636 / 545;

		let h = window.innerHeight;
		let w = window.innerWidth;

		let { h1, h2, pp } = this.props;

		if (!this.state.inView) {
			return (
				<View
					style={{
						width: window.innerWidth,
						height: window.innerHeight,
						backgroundColor: "#171A20",
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						top: 0,
						zIndex: 999,
					}}>
					<Image
						source={logo_tetrice}
						style={{
							width: 200,
							height: 100,
							resizeMode: "contain",
						}}
					/>
				</View>
			);
		} else
			return (
				<View
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}
					style={{
						// flex: 1,
						width: "100%",
						// height: window.innerHeight * 0.85,
						// paddingLeft: "15%",
						// backgroundColor: "#171A20",
						// padding: "5%",
						// minHeight: 750,
						marginBottom: 50,
					}}>
					<View
						style={{
							width: "100%",
							flexDirection: "row",
							flexWrap: "wrap",
							// height: window.innerHeight * 0.65,
							minHeight: 600,
							backgroundColor: "#171A20",
							// backgroundColor: "#272525",
							padding: "10%",
							// maxHeight: 900,
							// overflow: "hidden",
							borderRadius: 15,
							// marginTop: 40,
							borderTopLeftRadius: 0,
							borderTopRightRadius: 0,
							paddingTop: 40,
							borderWidth: 0.5,
							borderColor: this.state.hover
								? APP_COLOURS.BG5
								: APP_COLOURS.BG4,
						}}>
						<View
							style={{
								flex: 1,
								maxWidth: 500,
								opacity: 0.9,
								width: "100%",
								minWidth: 350,
							}}>
							<Image
								source={logo_tetrice}
								style={{
									width: "50%",
									maxWidth: 450,
									height: 130,
									resizeMode: "contain",
									marginVertical: 50,
								}}
							/>
							<View
								style={{
									flex: 1,
									// paddingLeft: 30,
									width: "100%",
								}}>
								<Text
									style={{
										fontSize: 45,
										color: "#FFF",
										top: 10,
									}}>
									<Font family='Poppins' weight={800}>
										tetrice
									</Font>
								</Text>
								<View style={{ flexDirection: "row" }}>
									<Text
										style={{
											fontSize: 20,
											color: "#FFF",
											marginRight: 5,
										}}>
										<Font family='Poppins' weight={600}>
											Consulting
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 20,
											color: "#FFF",
										}}>
										<Font family='Poppins' weight={300}>
											Services
										</Font>
									</Text>
								</View>
							</View>
							<View
								style={{
									height: 10,
									width: "25%",
									borderRadius: 10,
									backgroundColor: APP_COLOURS.YELLOW,
									marginTop: 30,
								}}
							/>
							<Fade cascade left>
								<Text
									style={{
										fontSize: h1,
										color: "#FFF",
									}}>
									<Font family='Poppins' weight={800}>
										{this.props.documentTitle}
									</Font>
								</Text>

								<Text
									style={{
										fontSize: h2,
										color: "#FFF",
									}}>
									<Font family='Poppins' weight={400}>
										Proposal Prepared for{" "}
										{this.props.clientName}
									</Font>
								</Text>
							</Fade>
						</View>
						<View
							style={{
								flex: 1,
								minWidth: 350,
								justifyContent: "flex-end",
								alignItems: "flex-end",
							}}>
							<View
								style={{
									// flex: 1,
									// position: "absolute",
									// right: -60,
									// bottom: -60,
									right: 0,
									bottom: 0,
									width: "100%",
									height: 350,

									// backgroundColor: "red",
									// opacity: 0.3,
									// maxHeight: "60%",
									justifyContent: "flex-end",
									alignItems: "flex-end",
								}}>
								<Image
									// source={bg1}
									source={require("./assets/shoutImage.png")}
									style={{
										width: 350 * ar,
										height: 350,
										maxHeight: 350,
										maxWidth: 350 * ar,
										resizeMode: "contain",
									}}
								/>
							</View>
						</View>
						<View
							style={{
								width: "100%",
								// backgroundColor: "#1C2B46",
								height: 65,
								marginTop: 30,
								// position: "absolute",
								// bottom: 0,
								// left: 0,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<View
								style={{
									width: "100%",
									flexDirection: "row",
									// maxWidth: 1200,
									justifyContent: "space-between",
								}}>
								<Image
									source={require("./assets/services.png")}
									style={{
										width: 250,
										height: 35,
										resizeMode: "contain",
									}}
								/>
								<Image
									source={require("./assets/infod.png")}
									style={{
										width: 220,
										height: 35,
										resizeMode: "contain",
									}}
								/>
							</View>
						</View>
					</View>
					{/* <View
						style={{
							width: "100%",
							// backgroundColor: "#1C2B46",
							height: 65,
							// position: "absolute",
							// bottom: 0,
							// left: 0,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								width: "100%",
								flexDirection: "row",
								maxWidth: 1200,
								justifyContent: "space-between",
							}}>
							<Image
								source={require("./assets/services.png")}
								style={{
									width: 300,
									height: 35,
									resizeMode: "contain",
								}}
							/>
							<Image
								source={require("./assets/infod.png")}
								style={{
									width: 220,
									height: 35,
									resizeMode: "contain",
								}}
							/>
						</View>
					</View> */}
				</View>
			);
	}
}

class ProposalPages extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inView: false,
			hover: false,
		};
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		let { h1, h2, h3, pp, w, i, d } = this.props;
		let h = window.innerHeight;

		let imgWid = w / d.body.length > 400 ? 400 : w / d.body.length;

		let inview = false;

		return (
			<div
				style={{
					// width: w,
					width: "100%",
					height: "auto",
					// justifyContent: "center",
					// alignItems: "center",
					zIndex: 5,
					// padding: "5%",
					// marginTop: 50,
					// marginBottom: 50,
					overflow: "hidden",
				}}>
				<View
					// onMouseEnter={() => this.setState({ hover: true })}
					// onMouseLeave={() => this.setState({ hover: false })}
					style={{
						height: "auto",
						width: "100%",
						zIndex: 1,
						marginLeft: "auto",
						marginRight: "auto",
						// opacity: this.state.inView ? 1 : 0.5,
						// opacity: this.state.hover ? 1 : 0.9,
						// backgroundColor: this.state.inView
						// 	? "#272525"
						// 	: "transparent",
						// borderBottomWidth: 0.5,
						// borderRadius: 25,
						borderColor: this.state.hover
							? APP_COLOURS.BG5
							: APP_COLOURS.BG4,
						// overflow: "hidden",

						// minHeight: 800,
					}}>
					<InView
						as='div'
						delay={300}
						trackVisibility={true}
						onChange={(bool, entry) => {
							clearTimeout(this._timeout);
							this._timeout = setTimeout(() => {
								// console.log(bool);
								this.setState({
									inView: bool,
									showObjects: bool,
									showPhone: bool,
								});
							}, 500);
						}}>
						<div
							style={{
								height: "100%",
								width: "100%",
								position: "absolute",
								top: 30,
								// backgroundColor: "red",
							}}
						/>
					</InView>
					<View
						style={{
							width: "100%",
						}}
					/>
					<div
						style={{}}
						// className={this.state.inView ? `reveal` : `fadeOut`}
					>
						<View
							style={{
								flexDirection:
									window.innerWidth < 700 ? "column" : "row",
								width: "100%",
								justifyContent: "space-between",
								marginTop: "5%",
							}}>
							{d.body.map((dd) =>
								dd.type === "img" ? (
									<View
										style={
											{
												// paddingLeft: "5%",
											}
										}>
										<View
											style={{
												borderRadius: 5,
												overflow: "hidden",
												borderWidth: 1,
												borderColor: APP_COLOURS.BG5,
											}}>
											<Image
												source={dd.data}
												style={{
													width: dd.width,
													height: dd.height,
													resizeMode: dd.resizeMode,
													// marginBottom: h1,
												}}
											/>
										</View>
									</View>
								) : dd.type === "h1" ? (
									<View
										style={{
											flex: 1,
											// marginTop: h1,
											// marginBottom: h1,
											// padding: "5%",
											// paddingLeft: "5%",
											width: "100%",
											paddingVertical: 0,
											// marginTop: "15%",
										}}>
										<Text
											style={{
												fontSize: h1,
												color: APP_COLOURS.WHITE,
											}}>
											<Font
												family='Montserrat'
												weight={700}>
												{dd.text}
											</Font>
										</Text>

										<View
											style={{
												height:
													window.innerHeight * 0.005,
												width: "20%",
												borderRadius: 10,
												backgroundColor:
													APP_COLOURS.YELLOW,
												marginVertical: 10,
												// opacity: 0.6,
											}}
										/>

										<Text
											style={{
												fontSize: pp,
												marginBottom: pp,
												flexDirection: "row",
												// opacity: 0.4,
												color: APP_COLOURS.WHITE,
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												{dd.subtitle}
											</Font>
										</Text>
										<Text
											style={{
												fontSize: pp,
												color: APP_COLOURS.WHITE,
												marginBottom: pp,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={400}>
												{dd.subParagraph}
											</Font>
										</Text>

										{dd.ul?.map((ddd) => (
											<View
												style={{
													width: "100%",
													marginBottom: 5,
													maxWidth: 550,
												}}>
												<View
													style={{
														fontSize: pp,
														color: APP_COLOURS.WHITE,
														// marginBottom: 5,
														// flexDirection: "row",
														// paddingLeft: 15,
													}}>
													<View
														style={{
															alignItems:
																"center",
															flexDirection:
																"row",
														}}>
														<View
															style={{
																width: 25,
																height:
																	window.innerHeight *
																	0.005,
																backgroundColor:
																	APP_COLOURS.PURPLE,
																borderRadius: 5,

																marginRight: 10,
																opacity: 0.5,
															}}
														/>
														<Text
															style={{
																fontSize: pp,
																color: APP_COLOURS.WHITE,
																// marginBottom: pp,
																flexDirection:
																	"row",
																// paddingLeft: 35,
															}}>
															<Font
																family='Montserrat'
																weight={500}>
																{ddd.text}
															</Font>
														</Text>
													</View>

													<Text
														style={{
															fontSize: pp * 0.9,
															color: APP_COLOURS.WHITE,
															marginBottom: pp,
															flexDirection:
																"row",
															paddingLeft: 35,
														}}>
														<Font
															family='Montserrat'
															weight={300}>
															{ddd.subtitle}
														</Font>
													</Text>
												</View>
											</View>
										))}
										{dd.ol?.map((ddd, ii) => (
											<View
												style={{
													width: "100%",
													marginBottom: 5,
													maxWidth: 550,
												}}>
												<View
													style={{
														width: "100%",
														flexDirection: "row",
														// alignItems: "center",
													}}>
													<View
														style={{
															width: 50,
															height: 50,
															backgroundColor:
																APP_COLOURS.PURPLE,
															borderRadius: 5,
															justifyContent:
																"center",
															alignItems:
																"center",
															marginRight: 10,
														}}>
														<Text
															style={{
																fontSize: pp,
																color: APP_COLOURS.WHITE,
																flexDirection:
																	"row",
															}}>
															<Font
																family='Montserrat'
																weight={600}>
																{ii + 1}
															</Font>
														</Text>
													</View>
													<View style={{ flex: 1 }}>
														<Text
															style={{
																flex: 1,
																fontSize: pp,
																color: APP_COLOURS.WHITE,
																paddingLeft: pp,
															}}>
															<Font
																family='Montserrat'
																weight={500}>
																{ddd.text}
															</Font>
														</Text>
														<Text
															style={{
																fontSize:
																	pp * 0.9,
																color: APP_COLOURS.WHITE,
																marginBottom:
																	pp,
																flexDirection:
																	"row",
																paddingLeft: pp,
																// paddingLeft: 80,
															}}>
															<Font
																family='Montserrat'
																weight={300}>
																{ddd.subtitle}
															</Font>
														</Text>
													</View>
												</View>
											</View>
										))}
									</View>
								) : dd.type === "h2" ? (
									<View
										style={{
											flex: 1,
											// marginTop: h2,
											// marginBottom: h2,
											// padding: "5%",
											// paddingLeft: "5%",
											paddingVertical: 0,
											marginTop: "5%",
										}}>
										<Text
											style={{
												fontSize: h2,
												color: APP_COLOURS.WHITE,

												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												{dd.text}
											</Font>
										</Text>
									</View>
								) : dd.type === "points" ? (
									<View style={{ flex: 1, marginTop: "5%" }}>
										<View
											style={{
												// flex: 1,
												// justifyContent: "center",
												alignItems: "center",
												paddingVertical: 0,
												// padding: 5,
												// marginTop: 20,
												width: "100%",
											}}>
											<Text
												style={{
													fontSize: h1,
													color: APP_COLOURS.WHITE,
													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													{dd.text}
												</Font>
											</Text>
											<Text
												style={{
													fontSize: h2,
													color: APP_COLOURS.WHITE,
													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													{dd.subtitle}
												</Font>
											</Text>
										</View>
										<View
											style={{
												flex: 1,
												marginTop: "5%",
												justifyContent: "space-between",
												flexDirection: "row",
												paddingVertical: 0,
												padding: 5,
											}}>
											{dd.items?.map((ty) => (
												<View
													style={{
														flex: 1,
														// justifyContent:
														// 	"center",
														// alignItems: "center",
														paddingVertical: 0,
														padding: 5,
														borderRadius: 25,
														// borderRightTopRadius: 15,
														// borderLeftBottomRadius: 15,
														marginLeft: 10,
														borderBottomWidth: 1,
														borderColor:
															APP_COLOURS.PURPLE,
														backgroundColor:
															APP_COLOURS.BG3,
													}}>
													<Text
														style={{
															fontSize: h2,
															color: APP_COLOURS.WHITE,
															flexDirection:
																"row",
															textAlign: "center",
														}}>
														<Font
															family='Montserrat'
															weight={800}>
															{ty.text}
														</Font>
													</Text>
													<Text
														style={{
															fontSize: pp,
															color: APP_COLOURS.WHITE,
															flexDirection:
																"row",
															textAlign: "center",
														}}>
														<Font
															family='Montserrat'
															weight={500}>
															{ty.subtitle}
														</Font>
													</Text>
												</View>
											))}
										</View>
									</View>
								) : dd.type === "p" ? (
									<Text
										style={{
											flex: 1,
											fontSize: pp,
											color: APP_COLOURS.WHITE,
											marginBottom: pp,
											fontFamily: "Montserrat",
											textAlign: "justify",
											fontWeight: "200",
											opacity: 0.8,
											// padding: "5%",
											// paddingLeft: "5%",
										}}>
										{/* <Font
												family='Montserrat'
												weight={300}> */}
										{dd.text}
										{/* </Font> */}
									</Text>
								) : dd.type === "ul" ? (
									<View
										style={{
											flex: 1,
											// padding: "5%",
											// paddingLeft: "5%",
											// paddingVertical: 10,
										}}>
										<Text
											style={{
												fontSize: h3,
												color: APP_COLOURS.WHITE,
												marginBottom: 5,
												flexDirection: "row",
												display:
													dd.text !== ""
														? "flex"
														: "none",
											}}>
											<Font
												family='Montserrat'
												weight={600}>
												{dd.text}
											</Font>
										</Text>
										<Text
											style={{
												fontSize: pp,
												color: APP_COLOURS.WHITE,
												marginBottom: 15,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												{dd.subtitle}
											</Font>
										</Text>
										{dd.items?.map((ddd) => (
											<>
												<View
													style={{
														fontSize: pp,
														color: APP_COLOURS.WHITE,
														// marginBottom: 5,
														// flexDirection: "row",
														// paddingLeft: 15,
														maxWidth: 550,
													}}>
													<View
														style={{
															alignItems:
																"center",
															flexDirection:
																"row",
														}}>
														<View
															style={{
																width: 25,
																height:
																	window.innerHeight *
																	0.005,
																backgroundColor:
																	APP_COLOURS.PURPLE,
																borderRadius: 5,

																marginRight: 10,
																opacity: 0.5,
															}}
														/>
														<Text
															style={{
																fontSize: pp,
																color: APP_COLOURS.WHITE,
																// marginBottom: pp,
																flexDirection:
																	"row",
																// paddingLeft: 35,
															}}>
															<Font
																family='Montserrat'
																weight={500}>
																{ddd.text}
															</Font>
														</Text>
													</View>

													<Text
														style={{
															fontSize: pp * 0.9,
															color: APP_COLOURS.WHITE,
															marginBottom: pp,
															flexDirection:
																"row",
															paddingLeft: 35,
														}}>
														<Font
															family='Montserrat'
															weight={300}>
															{ddd.subtitle}
														</Font>
													</Text>
												</View>
											</>
										))}
									</View>
								) : dd.type === "ol" ? (
									<View
										style={{
											flex: 1,
											// padding: "5%",
											// paddingLeft: "5%",
										}}>
										<Text
											style={{
												fontSize: h3,
												color: APP_COLOURS.WHITE,
												marginBottom: 5,
												flexDirection: "row",
												display:
													dd.text !== ""
														? "flex"
														: "none",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												{dd.text}
											</Font>
										</Text>
										<Text
											style={{
												fontSize: pp,
												color: APP_COLOURS.WHITE,
												marginBottom: 15,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												{dd.subtitle}
											</Font>
										</Text>
										{dd.items?.map((ddd, ii) => (
											<View
												style={{
													width: "100%",
													// marginBottom: pp,
													marginBottom: "5%",
													maxWidth: 550,
												}}>
												<View
													style={{
														width: "100%",
														flexDirection: "row",
														// alignItems: "center",
													}}>
													<View
														style={{
															width: 50,
															height: 50,
															backgroundColor:
																APP_COLOURS.PURPLE,
															borderRadius: 5,
															justifyContent:
																"center",
															alignItems:
																"center",
															marginRight: 10,
														}}>
														<Text
															style={{
																fontSize: pp,
																color: APP_COLOURS.WHITE,
																flexDirection:
																	"row",
															}}>
															<Font
																family='Montserrat'
																weight={600}>
																{ii + 1}
															</Font>
														</Text>
													</View>
													<View style={{ flex: 1 }}>
														<Text
															style={{
																flex: 1,
																fontSize: pp,
																color: APP_COLOURS.WHITE,
																paddingLeft: pp,
															}}>
															<Font
																family='Montserrat'
																weight={500}>
																{ddd.text}
															</Font>
														</Text>
														<Text
															style={{
																fontSize:
																	pp * 0.9,
																color: APP_COLOURS.WHITE,
																marginBottom:
																	pp,
																flexDirection:
																	"row",
																paddingLeft: pp,
																// paddingLeft: 80,
															}}>
															<Font
																family='Montserrat'
																weight={300}>
																{ddd.subtitle}
															</Font>
														</Text>
													</View>
												</View>
												{/* <Text
													style={{
														fontSize: pp * 0.8,
														color: APP_COLOURS.WHITE,
														marginBottom: pp,
														flexDirection: "row",
														paddingLeft: 80,
													}}>
													<Font
														family='Montserrat'
														weight={300}>
														{ddd.subtitle}
													</Font>
												</Text> */}
											</View>
										))}
									</View>
								) : dd.type === "slide" ? (
									<View
										style={{
											flex: 1,
											// padding: "5%",
										}}>
										<ImageLoop
											steps={dd.items}
											stepTitle={(title) =>
												this.setState({
													stepTitle: title,
												})
											}
										/>
									</View>
								) : (
									<View
										style={{
											flex: 1,
										}}
									/>
								),
							)}
						</View>
					</div>
				</View>
			</div>
		);
	}
}
