import React, { Component } from "react";
import { View, Image, TouchableOpacity, Linking, Text } from "react-native";
import { ScrollView } from "react-native-web";

export default class Overview extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handlePress = (url) => {
		Linking.openURL(url);
	};

	render() {
		const items = [
			// {
			// 	title: "Overview",
			// 	description: "Overview",
			// 	url: "/proposal/scope/",
			// },
			{
				title: "PR, Content & Community Management:",
				description: "Proposal for Revlon & Elizabeth Arden",
				url: "/proposal/communitymanagement",
			},

			{
				title: "Community Management:",
				description: "REVLON & Elizabeth Arden - Cost estimate",
				url: "/ce/revloncman",
			},
			// {
			// 	title: "Community Management:",
			// 	description: " - Cost estimate",
			// 	url: "/ce/elizabethcman",
			// },
			{
				title: "Content Creation:",
				description: "REVLON & Elizabeth Arden - Cost estimate",
				url: "/ce/ccrev",
			},
			// {
			// 	title: "Content Creation:",
			// 	description: "Elizabeth Arden - Cost estimate",
			// 	url: "/ce/ccrev",
			// },
			{
				title: "Secondment: Jenae Collins",
				description: "Cost estimate",
				url: "/ce/secondment",
			},
			// {
			// 	title: "SEO & #####",
			// 	description: "Cost estimate",
			// 	url: "/ce/seo",
			// },
			{
				title: "Skin tools: WebApp",
				description: "Proposal - Cost estimate",
				url: "/ce/proposal/skintools",
			},
			{
				title: "Skin Challenge: Native App",
				description: "Proposal - Cost estimate",
				url: "/ce/proposal/skinchallenge",
			},
			{
				title: "Ecommerce management Retainer",
				description: "Cost estimate",
				url: "/ce/digitalmanagement",
			},
			{
				title: "OH SHIP:",
				description: " - Event proposal",
				url: "/proposal/ohship",
			},
			// {
			// 	title: "IoT device: in store",
			// 	description: "CE",
			// 	url: "/instoreiotdisplay",
			// },
			// {
			// 	title: "RSVP Event Mailer",
			// 	description: "CE",
			// 	url: "/rsvpevent",
			// },
			// {
			// 	title: "CON Mobile",
			// 	description: "CE",
			// 	url: "/conmobile",
			// },
		];
		return (
			<View
				style={{
					flex: 1,
					height: window.innerHeight,
					// backgroundColor: "#1C2B46",

					backgroundColor: "#121419",
					// width: "100%",
					// alignItems: "center",
				}}>
				<View
					style={{
						width: "100%",
						height: window.innerHeight,
					}}>
					<ScrollView showVerticalIndicator={false}>
						<View
							style={{
								// flex: 1,
								// width: "100%",
								padding: "5%",
								width: "100%",
								// alignItems: "center",
								maxWidth: 1200,
								marginLeft: "auto",
								marginRight: "auto",
							}}>
							<View
								style={{
									width: "100%",
									flexDirection: "row",
									justifyContent: "space-between",
									flexWrap: "wrap-reverse",
								}}>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
										minWidth: 250,
									}}>
									<TouchableOpacity
										onPress={() =>
											this.setState({
												showSidebar:
													!this.state.showSidebar,
											})
										}>
										<Image
											source={require("./assets/tetrice_consulting.png")}
											style={{
												width: 120,
												height: 160,
												resizeMode: "contain",
											}}
										/>
									</TouchableOpacity>
								</View>
								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// minWidth: 320,
										width: "100%",
									}}>
									<TouchableOpacity
										style={{
											// backgroundColor: "#FFF",
											// backgroundColor: "#1F2229",
											// padding: 20,
											borderRadius: 15,
											width: "100%",
											// alignItems: "center",
											// justifyContent: "center",
										}}
										onPress={() =>
											this.setState({
												showSidebar:
													!this.state.showSidebar,
											})
										}>
										<View
											style={{
												width: "80%",
												maxWidth: 400,
											}}>
											<img
												src={require("./assets/tetrice-revlon-main.png")}
												alt='Description'
												className='responsive'
											/>
										</View>
									</TouchableOpacity>
								</View>
							</View>
							<View
								style={{
									width: "100%",
									height: 0.5,
									backgroundColor: "#1F2229",
								}}
							/>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									justifyContent: "space-between",
									width: "100%",
								}}>
								<View
									style={{
										flex: 1,
										paddingTop: 30,
										justifyContent: "flex-start",
										alignItems: "flex-start",
										flexWrap: "wrap",
										// maxWidth: 1200,
									}}>
									{items.map((item, i) => (
										<TouchableOpacity
											key={i}
											style={{
												padding: 10,
												// backgroundColor: "#3A475E",
												backgroundColor: "#1F2229",
												marginBottom: 20,
												borderRadius: 10,
												width: "100%",
												minWidth: 300,
												maxWidth: 340,
											}}
											onPress={() =>
												this.handlePress(item.url)
											}>
											<Text
												style={{
													fontSize: 16,
													fontWeight: "bold",
													color: "#FFF",
													opacity: 0.8,
												}}>
												{item.title}
											</Text>
											<Text
												style={{
													fontSize: 14,
													color: "#FFF",
													opacity: 0.5,
												}}>
												{item.description}
											</Text>
										</TouchableOpacity>
									))}
								</View>
							</View>
							{/* <View
								style={{
									// flexDirection: "row",
									justifyContent: "flex-end",
									// maxWidth: 1200,
									flex: 1,
									alignItems: "flex-end",
								}}>
								<Image
									source={require("./assets/services.png")}
									style={{
										width: "70%",
										height: 100,
										maxWidth: 300,
										resizeMode: "contain",
									}}
								/>
								<Image
									source={require("./assets/infod.png")}
									style={{
										width: "70%",
										maxWidth: 300,
										height: 100,
										resizeMode: "contain",
									}}
								/>
							</View> */}
						</View>
					</ScrollView>
				</View>
			</View>
		);
	}
}
