import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Font from "react-font";
import Fade from "react-reveal/Fade";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/76961-weather-alert-notification.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";

import moment from "moment";
import ImageLoop from "./ImageLoop";

export default class ProposalPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inView: false,
			clientLogo: "",
			pages: [
				// {
				// 	body: [
				// 		"",
				// 		"",
				// 		{
				// 			type: "img",
				// 			data: require("./assets/eaLogo.png"),
				// 			width: 230,
				// 			height: 116,
				// 		},
				// 		"",
				// 		"",
				// 	],
				// },
				// {
				// 	body: [
				// 		{
				// 			type: "h1",
				// 			text: "Revlon & Elizabeth Arden",
				// 			subtitle: `SOCIAL MEDIA CONTENT CREATION`,
				// 		},
				// 		{
				// 			type: "img",
				// 			data: require("./assets/shoutImage.png"),
				// 			width: 181.79,
				// 			height: 259,
				// 		},
				// 	],
				// },
			],

			costEstimate: {
				clientDetails: "Revlon & Elizabeth Arden",
				title: "SOCIAL MEDIA CONTENT CREATION",
				description: ``,

				lines: [
					{
						title: "REVLON - Content creation",
						description: `Monthly production of 80 social media posts, tailored for use on Facebook, Instagram, and Twitter across REVLON and associated brands.
Content production includes content planning, writing, proofing, art direction, design, and motion graphics.
Assumes a cadence of approximately 3-4 posts per week per platform, with a mix of Stories, Reels, still in-feed posts, and short (2-3 sec) motion posts.
This proposal covers REVLON brands listed below. Note: exact volumes of brand accounts and channels to be confirmed, subject to change costs. Should the requirement change for any of the brands, a recosting will be necessary.

Stock images: 12 celebrity Gallo images and 40 Shutterstock images shared across REVLON and associated brands.
Includes posting and scheduling on Facebook, Twitter, and Instagram.
Monthly reporting using native social media tools.`,
						price: "R 153,300",
					},
					{
						title: "Elizabeth Arden - Content creation",
						description: `Monthly production of 80 social media posts, tailored for use on Facebook, Instagram, and Twitter across Elizabeth Arden and associated brands.
Content production includes content planning, writing, proofing, art direction, design, and motion graphics.
Assumes a cadence of approximately 3-4 posts per week per platform, with a mix of Stories, Reels, still in-feed posts, and short (2-3 sec) motion posts.
This proposal covers Elizabeth Arden brands listed below. Note: exact volumes of brand accounts and channels to be confirmed, subject to change costs. Should the requirement change for any of the brands, a recosting will be necessary.

Stock images: 12 celebrity Gallo images and 40 Shutterstock images shared across Elizabeth Arden and associated brands.
Includes posting and scheduling on Facebook, Twitter, and Instagram.
Monthly reporting using native social media tools.`,
						price: "R 67,400",
					},
					{
						title: "tetrice team",
						description: `Digital director, account director, digital editor, copywriter, multi-media designer, mid-level designer, copy editor, traffic manager`,
						price: "",
					},
				],
				additional: ``,
				terms: ``,

				expiry: moment("01/08/2023", "DD/MM/YYYY").format(
					"DD MMM YYYY",
				),
			},
		};
	}

	pages = (d) => {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = window.innerHeight;
		let w = window.innerWidth > 1000 ? 1000 : window.innerWidth;

		let imgWid = w / d.body.length > 400 ? 400 : w / d.body.length;

		return (
			<div
				style={{
					width: window.innerWidth,
					height: "auto",
					justifyContent: "center",
					alignItems: "center",
					zIndex: 5,
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							width: w,
							// height: h,
							height: "auto",

							zIndex: 1,
							maxWidth: 1200,
						}}>
						<Fade>
							<View
								style={{
									flexDirection: "row",
									width: "100%",
									justifyContent: "space-between",
									marginBottom: 10,
								}}>
								{d.body.map((dd) =>
									dd.type === "img" ? (
										<Image
											source={dd.data}
											style={{
												width: imgWid,
												height: imgWid,
												resizeMode: "contain",
											}}
										/>
									) : dd.type === "h1" ? (
										<View
											style={{
												flex: 1,
												marginTop: 20,
												marginBottom: 10,
											}}>
											<Text
												style={{
													fontSize: 40,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={600}>
													{dd.text}
												</Font>
											</Text>
											<Text
												style={{
													fontSize: 22,
													marginBottom: 5,
													flexDirection: "row",
													opacity: 0.4,
													color: "#000",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													{dd.subtitle}
												</Font>
											</Text>
										</View>
									) : dd.type === "h2" ? (
										<View
											style={{
												flex: 1,
												marginTop: 20,
												marginBottom: 10,
											}}>
											<Text
												style={{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,

													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													{dd.text}
												</Font>
											</Text>
										</View>
									) : dd.type === "p" ? (
										<Text
											style={{
												flex: 1,
												fontSize: 16,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 5,
												fontFamily: "Montserrat",
												textAlign: "justify",
												fontWeight: "300",
											}}>
											{/* <Font
													family='Montserrat'
													weight={300}> */}
											{dd.text}
											{/* </Font> */}
										</Text>
									) : dd.type === "li" ? (
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													{dd.text}
												</Font>
											</Text>
											<Text
												style={{
													fontSize: 16,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 15,
													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={300}>
													{dd.subtitle}
												</Font>
											</Text>
											{dd.items.map((ddd) => (
												<>
													<Text
														style={{
															fontSize: 15,
															color: APP_COLOURS.TEXTCOLOR,
															marginBottom: 5,
															flexDirection:
																"row",
															// paddingLeft: 15,
														}}>
														<Font
															family='Montserrat'
															weight={500}>
															- {ddd.text}
														</Font>
													</Text>
													<Text
														style={{
															fontSize: 14,
															color: APP_COLOURS.TEXTCOLOR,
															marginBottom: 15,
															flexDirection:
																"row",
															paddingLeft: 15,
														}}>
														<Font
															family='Montserrat'
															weight={300}>
															{ddd.subtitle}
														</Font>
													</Text>
												</>
											))}
										</View>
									) : dd.type === "slide" ? (
										<View
											style={{
												flex: 1,
											}}>
											<ImageLoop
												steps={dd.items}
												stepTitle={(title) =>
													this.setState({
														stepTitle: title,
													})
												}
											/>
										</View>
									) : null,
								)}
							</View>
						</Fade>
					</View>
				</View>
			</div>
		);
	};

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = window.innerHeight;
		let w = window.innerWidth > 1000 ? 1000 : window.innerWidth;

		let ce = this.state.costEstimate;

		return (
			<View
				style={{
					padding: 20,
					// paddingTop: "10%",
					overflow: "hidden",
					// backgroundColor: "#EDF1F4",
					width: "100%",
				}}>
				{this.state.pages.map((d) => this.pages(d))}
				<div
					style={{
						// flex: 1,
						width: window.innerWidth,
						// height: h,
						// height: "auto",
						// backgroundColor: "#1F2229",
						// backgroundColor: "#FFF",
						// overflow: "hidden",
						justifyContent: "center",
						alignItems: "center",
						paddingTop: "5%",
						// flex: 1,
					}}>
					<View
						style={{
							position: "absolute",
							bottom: 0,
							left: 0,
						}}>
						<Image
							source={require("./assets/dots.png")}
							style={{
								width: 500,
								height: 500,
								resizeMode: "contain",
							}}
						/>
					</View>
					<View
						style={{
							position: "absolute",
							top: 0,
							right: 0,
							zIndex: 1,
						}}>
						<Image
							source={require("./assets/dots.png")}
							style={{
								width: 500,
								height: 500,
								resizeMode: "contain",
							}}
						/>
					</View>
					<View
						style={{
							position: "absolute",
							bottom: 84,
							right: 0,
						}}>
						<Image
							source={require("./assets/agreeMent.png")}
							style={{
								width: 200 * (368.94 / 255.42),
								height: 200,
								resizeMode: "contain",
							}}
						/>
					</View>
					<View
						style={{
							width: "100%",
							backgroundColor: "#1C2B46",
							height: 85,
							position: "absolute",
							bottom: 0,
							left: 0,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								width: "100%",
								flexDirection: "row",
								maxWidth: 1200,
								justifyContent: "space-between",
							}}>
							<Image
								source={require("./assets/services.png")}
								style={{
									width: 300,
									height: 35,
									resizeMode: "contain",
								}}
							/>
							<Image
								source={require("./assets/infod.png")}
								style={{
									width: 220,
									height: 35,
									resizeMode: "contain",
								}}
							/>
						</View>
					</View>

					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							paddingBottom: 250,
						}}>
						<View
							style={{
								width: w,

								// height: h,
								height: "auto",
								maxWidth: 1200,

								flex: 1,
								zIndex: 1,
							}}>
							<View
								style={{
									// flex: 1,
									flexDirection: "row",
									// height: h * 0.2,
									width: w,
									// marginBottom: "40%",
								}}>
								<View
									style={{
										flex: 1,
										// padding: 20,
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={{
												fontSize: 30,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											<Font family='Poppins' weight={500}>
												{ce.clientDetails}
											</Font>
										</Text>
										<Text
											style={{
												fontSize: 35,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											<Font family='Poppins' weight={700}>
												{ce.title}
											</Font>
										</Text>
									</View>
								</View>
							</View>

							<View
								style={{
									width: w,
								}}>
								{ce.lines.map((dd) => (
									<View
										style={{
											marginTop: 20,
											marginBottom: 20,
											paddingBottom: 10,
											borderBottomWidth: 0.5,
											borderColor: APP_COLOURS.TEXTCOLOR,
										}}>
										<View
											style={{
												flexDirection: "row",
												justifyContent: "space-between",
											}}>
											<Text
												style={{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 6,
												}}>
												<Font
													family='Montserrat'
													weight={700}>
													{dd.title}
												</Font>
											</Text>
											<Text
												style={{
													fontSize: 16,
													color: APP_COLOURS.TEXTCOLOR,

													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={700}>
													{dd.price}
												</Font>
											</Text>
										</View>

										<Text
											style={{
												fontSize: 15,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												{dd.description}
											</Font>
										</Text>
									</View>
								))}
							</View>

							{/* <View
								style={{
									width: "100%",
									borderWidth: 0.5,
									borderColor: APP_COLOURS.TEXTCOLOR,
								}}
							/> */}
							<View
								style={{
									flexDirection: "row",
									// marginBottom: 25,
									marginTop: 20,
									alignItems: "center",
									justifyContent: "space-between",
								}}>
								<Text
									style={{
										fontSize: 19,
										color: APP_COLOURS.TEXTCOLOR,

										flexDirection: "row",
									}}>
									<Font family='Montserrat' weight={500}>
										Total:
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 19,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 25,
										flexDirection: "row",
										marginTop: 25,
									}}>
									<Font family='Montserrat' weight={500}>
										R 219,000
									</Font>
								</Text>
							</View>

							{/* <View
								style={{
									width: "100%",
									borderWidth: 0.5,
									borderColor: APP_COLOURS.TEXTCOLOR,
								}}
							/> */}

							<Text
								style={{
									fontSize: 15,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
									marginTop: 2,
								}}>
								<Font family='Montserrat' weight={300}>
									{ce.additional}
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 15,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
									marginTop: 2,
								}}>
								<Font family='Montserrat' weight={300}>
									{ce.terms}
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 13,
									color: moment().isAfter(moment(ce.expiry))
										? APP_COLOURS.RED
										: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
									marginTop: 50,
								}}>
								<Font family='Montserrat' weight={300}>
									* This quotation is valid until {ce.expiry}
									{moment().isAfter(moment(ce.expiry))
										? ", and has expired"
										: `, expires ${moment(
												ce.expiry,
										  ).fromNow()}`}
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 13,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 2,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									* All quoted amounts are monthly recurring
									and exclusive of VAT unless stated
									otherwise.
								</Font>
							</Text>
						</View>
					</View>
				</div>
			</View>
		);
	}
}
