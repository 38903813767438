import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import Lottie from "react-lottie";
import CircularFlow from "./CircularFlow";
import { APP_COLOURS } from "./APP_VARS";

import "./resizeScale.scss";
import "./hoverEffect.scss";

import * as animationData from "./assets/126232-branding.json";

export default class Scope extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false, title: "Brand Journey Map" };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = this.props.screenHeight;
		let w = this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		let mw = 1200;

		let fontS = {
			fontSize: 20,
			color: APP_COLOURS.TEXTCOLOR,
			fontFamily: "Montserrat",
			textAlign: "justify",
			fontWeight: "300",
		};

		let { h1, h2, pp } = this.props;

		return (
			<View
				style={{
					// flex: 1,
					width: w,
					// height: window.innerHeight,
					// height: h,
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#EDF1F4",
					marginBottom: 50,
					// padding: 20,
					paddingTop: 100,
					paddingBottom: 100,

					padding: "10%",
				}}>
				<View
					style={{
						// flex: 1,
						// width: "100%",

						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							// flex: 1,
							// paddingTop: "5%",
							zIndex: 1,
						}}>
						<Fade>
							<View
								style={{
									// flex: 1,
									flexDirection: "row",
									height: h * 0.33,
									// width: "100%",
									// marginBottom: "10%",

									width: window.innerWidth * 0.8,
									maxWidth: mw,
								}}>
								<View
									style={{
										flex: 1,

										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<View
										style={{
											flex: 1,
											height: 200,
										}}>
										<Text
											style={{
												fontSize: 40,
												height: 50,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											<Font family='Poppins' weight={900}>
												tetrice
											</Font>
										</Text>
										<Text
											style={{
												fontSize: 40,
												color: APP_COLOURS.RED,
												marginBottom: 10,
											}}>
											<Font family='Poppins' weight={600}>
												Brand Journey Map
											</Font>
										</Text>

										<Text
											style={{
												fontSize: 40,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											<Font family='Poppins' weight={600}>
												{this.state.title}
											</Font>
										</Text>
									</View>
								</View>
								<View
									style={{
										flex: 1,
										padding: 20,
									}}>
									<Lottie
										options={defaultOptions}
										height={h * 0.4}
										width={h * 0.51}
									/>
								</View>
							</View>
						</Fade>

						<Fade>
							<View
								style={{
									// flex: 1,
									flexDirection: "row",
									// height: h / 2,
									height: "auto",
									// width: "100%",
									width: window.innerWidth * 0.8,
									maxWidth: mw,
								}}>
								<View
									style={{
										flex: 1,
									}}>
									<View
										style={
											{
												// flexDirection: "row",
												// width: "100%",
											}
										}>
										<CircularFlow
											stepTitle={(t) =>
												this.setState({
													title: t,
												})
											}
										/>
									</View>

									<Text
										style={[
											fontS,
											{
												marginVertical: 10,
												flexDirection: "row",
											},
										]}>
										This flow highlights the critical steps
										in the PR and community management
										process, from understanding the brand's
										current position to implementing the
										strategy, expanding sales channels, and
										measuring success.
									</Text>
								</View>
							</View>
						</Fade>
					</View>
				</View>
			</View>
		);
	}
}
