import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/64198-find-target.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page3 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		let mw = 1200;
		let h = this.props.screenHeight;
		let w = this.props.screenWidth > mw ? mw : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		let fontS = {
			fontSize: 20,
			color: APP_COLOURS.TEXTCOLOR,
			fontFamily: "Montserrat",
			textAlign: "justify",
			fontWeight: "300",
		};

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
					// paddingBottom: "10%",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							// width: w,
							// height: h,
							height: "auto",
							// paddingTop: "10%",
							zIndex: 1,

							width: window.innerWidth * 0.8,
							maxWidth: mw,
						}}>
						<Fade>
							<div
							// className={
							// 	this.state.inView ? `reveal` : `fadeOut`
							// }
							>
								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h * 0.2,
										// width: w,
										marginBottom: "5%",
									}}>
									<View
										style={{
											flex: 1,
											padding: 20,
										}}>
										<Lottie
											options={defaultOptions}
											height={h * 0.45}
											width={h * 0.4}
										/>
									</View>
									<View
										style={{
											flex: 1,
											padding: 20,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													fontSize: 50,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={600}>
													Our Vision for Success
												</Font>
											</Text>
										</View>
									</View>
								</View>

								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h / 2,
										// width: w,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={[
												{
													fontSize: 22,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
													marginVertical: 20,
												},
												fontS,
											]}>
											Our vision is to create a unified
											and powerful beauty brand identity
											that leverages the strengths of both
											Revlon and Elizabeth Arden, driving
											innovation and captivating consumers
											worldwide. By combining the rich
											heritage and prestige of Elizabeth
											Arden with Revlon's dynamic and
											accessible appeal, we aim to deliver
											a comprehensive and engaging beauty
											experience that resonates with
											diverse audiences.
										</Text>

										<Text
											style={[
												{
													fontSize: 22,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
													marginVertical: 20,
												},
												fontS,
											]}>
											Our vision is to establish Revlon &
											Elizabeth Arden as a leading luxury
											retail brands, renowned for its
											exceptional customer engagement,
											innovative communication strategies,
											and exclusive community experiences.
											By leveraging the power of PR and
											community management, we aim to
											create a lasting connection with our
											target audience and elevate our
											brand's reputation in the luxury
											market. Our PR and community
											management strategy is designed to
											achieve the following objectives:
										</Text>

										<View
											style={{
												flex: 1,
												flexDirection: "row",
												marginVertical: 20,
												flexWrap: "wrap",
											}}>
											{[
												{
													title: "Increase brand awareness:",
													desc: "Utilize targeted PR efforts and compelling content to expand our reach,  Seamlessly integrate the values, messaging, and aesthetics of Revlon and Elizabeth Arden to create a unified brand experience that appeals to a broad range of consumers.",
												},
												{
													title: "Foster brand loyalty:",
													desc: "Cultivate a passionate and engaged community of beauty enthusiasts through personalized interactions, exclusive events, and relevant content that inspires trust and loyalty.",
												},
												{
													title: "Attract new customers: ",
													desc: "Leverage the influence of industry tastemakers and creative marketing campaigns to reach potential customers and showcase the unparalleled quality and craftsmanship of Revlon & Elizabeth Arden  products.",
												},
												{
													title: "Drive innovation and product growth:",
													desc: "Showcase our commitment to cutting-edge beauty solutions by highlighting our research, product innovations, and collaborations with industry experts, driving consumer interest and fueling revenue growth.",
												},
												{
													title: "Drive revenue growth: ",
													desc: "Strengthen our brand's position in the market, driving customer acquisition and repeat purchases, ultimately resulting in increased sales and profitability",
												},
												{
													title: "Enhance brand reputation: ",
													desc: "Establish Revlon & Elizabeth Arden  as an industry leader by showcasing our commitment to sustainability, social responsibility, and exceptional customer experiences.",
												},
											].map((d, i) => (
												<View
													style={{
														// flex: 1,
														padding: 20,
														backgroundColor: "#FFF",
														marginRight: 15,
														borderRadius: 20,
														width: 350,
														marginBottom: 15,
													}}>
													<Text
														style={[
															fontS,
															{
																marginBottom: 5,
																marginTop: 10,

																fontWeight:
																	"600",
															},
														]}>
														{d.title}
													</Text>
													<Text
														style={[
															{
																fontSize: 15,
																color: APP_COLOURS.TEXTCOLOR,
																marginBottom: 5,
																flexDirection:
																	"row",
															},
															fontS,
														]}>
														{d.desc}
													</Text>
												</View>
											))}
										</View>

										{/* <Text
											style={[
												{
													fontSize: 22,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												},
												fontS,
											]}>
											By focusing on these objectives and
											implementing a data-driven,
											customer-centric approach, we will
											propel Revlon & Elizabeth Arden to
											new heights in the luxury retail
											landscape, creating a legacy that
											endures for generations to come.
										</Text> */}
									</View>
								</View>
							</div>
						</Fade>
					</View>

					{/* <PageFooter /> */}
				</View>
			</div>
		);
	}
}
