import React, { Component, useEffect } from "react";
// import { View, Text, ActivityIndicator, Image } from "react-native";
// import axios from "axios";
// import { api_qrscanned } from "./Api";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

import Login from "./App/Unauthed/Login";
import Main from "./App/Main";
import Overview from "./App/Overview";
import Scope from "./App/Scope";
import Page15 from "./App/Page15";
import Page14 from "./App/Page14";
import Page16 from "./App/Page16";
import Secondment from "./App/Secondment";
import ProposalPage from "./App/ProposalPage";
import ProposalPage2 from "./App/Skintools";
import SkinChallenge from "./App/SkinChallenge";
import ProposalPage3 from "./App/MainProposal";
import {
	SOWData,
	arVrActivation,
	digitalRetainer,
	ohshipData,
	retinolevent,
} from "./App/ProposalData";
import Proposals from "./App/PROPOSALS";
import moment from "moment";
import MainProposal from "./App/MainProposal";
import ProposalDigitalStrategy from "./App/ProposalDigitalStrategy";

const withPreventZoom = (Component) => {
	return (props) => {
		useEffect(() => {
			const preventZoom = (e) => {
				if (e.ctrlKey) {
					e.preventDefault();
				}
			};

			window.addEventListener("wheel", preventZoom, { passive: false });

			// Cleanup function to remove event listener when the component unmounts
			return () =>
				window.removeEventListener("wheel", preventZoom, {
					passive: false,
				});
		}, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

		return <Component {...props} />;
	};
};

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectUrl: null,
			isLoading: true,
			isMounted: false,
			analyticsData: null,
			enterCompetition: false,
		};
	}

	render() {
		const prop = "/proposal/";
		const ce = "/ce/";
		const p = {
			login: prop,
			overview: prop + "overview",
			main: prop + ":proposalName",
			scope: prop + "scope",
			test: prop + "test",
		};
		const c = {
			skintools: ce + "proposal/skintools",
			test: ce + "proposal/test",
			skinchallenge: ce + "proposal/skinchallenge",
			secondment: ce + "secondment",
			contentmanahementrevlon: ce + "ccrev",
			communitman_revlon: ce + "revloncman/",
			communitman_elizabeth: ce + "elizabethcman",
			digitalmanagement: ce + "digitalmanagement",

			hydraplayactivation: "hydraplayactivation",
		};

		const proposals = [
			{
				title: "oh ship",
				path: "/proposal/ohship",
				data: ohshipData,
			},
			{
				title: "oh ship ce",
				path: "/proposal/ohship/ce",
				data: ohshipData,
			},
			{
				title: "retinol",
				path: "/proposal/retinol",
				data: retinolevent,
			},
			{
				title: "sow",
				path: "/proposal/sow",
				data: SOWData,
			},
			{
				title: "digitalretainer",
				path: "/proposal/digitalretainer",
				data: digitalRetainer,
			},
		];

		const apiProposals = [
			{
				title: "Hydraplay AR/VR Experience",
				path: "/proposal/jhHG76HVGCvERTDfgcgv",
				data: arVrActivation,
			},
		];

		return (
			<Router>
				<Routes>
					<Route path={p.login} exact element={<Login />} />
					<Route path={p.overview} exact element={<Overview />} />
					<Route path={p.main} element={<Main />} />
					<Route path={p.scope} exact element={<Scope />} />

					<Route path={c.secondment} exact element={<Secondment />} />
					<Route
						path={c.skintools}
						exact
						element={<ProposalPage />}
					/>
					<Route path={c.test} exact element={<ProposalPage3 />} />

					<Route
						path={c.skinchallenge}
						exact
						element={<SkinChallenge />}
					/>

					{proposals.map((d, i) => (
						<Route
							path={d.path}
							exact
							element={
								<ProposalPage3 data={d.data} showCE={true} />
							}
						/>
					))}

					{apiProposals.map((d, i) => (
						<Route
							path={d.path}
							exact
							element={
								<MainProposal data={d.data} showCE={true} />
							}
						/>
					))}

					<Route
						path={"/digitalstrategy/ey67cUlkn78yiug5hgc"}
						exact
						element={<ProposalDigitalStrategy />}
					/>

					<Route
						path={c.communitman_revlon}
						exact
						element={<Page14 />}
					/>
					<Route
						path={c.communitman_elizabeth}
						exact
						element={<Page15 />}
					/>
					<Route
						path={c.digitalmanagement}
						exact
						element={<Page16 />}
					/>
					<Route
						path={c.contentmanahementrevlon}
						exact
						element={<ProposalPage2 />}
					/>

					<Route
						path={c.hydraplayactivation}
						exact
						element={
							<Proposals
								data={[
									{
										body: [
											{
												type: "h1",
												text: `
Once a customer has used the skin diagnostics web application to find the right Elizabeth Arden products for their skin concerns `,
											},
										],
									},
									{
										body: [
											{
												type: "p",
												text: `
Once a customer has used the skin diagnostics web application to find the right Elizabeth Arden products for their skin concerns and needs, the next step would be to download the free Elizabeth Arden skincare app. This app would allow users to track their skincare progress and participate in a 7-day skin transformation challenge.

The skincare app would enable users to track their daily skincare routine and monitor the improvements in their skin over time. This could include tracking factors such as hydration, texture, and radiance. Users could also receive personalized skincare tips and advice based on their progress and needs.

Additionally, the app could offer a 7-day skin transformation challenge, where users follow a prescribed skincare routine using the recommended Elizabeth Arden products for seven days to achieve a visible improvement in their skin. This could include daily reminders to use the products, as well as helpful tips and guidance on how to maximize the benefits of the products.

By offering the skincare app and 7-day transformation challenge, Elizabeth Arden can provide an additional layer of value to their customers beyond just product recommendations. The app can help to build brand loyalty and engagement by providing users with a comprehensive and personalized skincare experience.`,
											},
										],
									},
								]}
								costEstimate={{
									clientDetails: "Elizabeth Arden",
									title: "Skin tools Web App",
									description: `To accurately determine the cost of the application, we need to create a flow diagram and wireframe to design the platform. This will enable us to determine the development and time required to complete the project, as well as the scale and detail of backend services. We must take into account important factors such as the amount of data to be streamed, the number of users the app will have, and the data being stored and distributed.

            Some aspects of the application involve development-only costs, while others require minimal development yet carry a monthly running cost. In addition, we have drafted a mock-up of the app to provide a visual representation of the proposed user interface. Of course, the user interface will be designed to reflect the brand's product colors.`,

									lines: [
										{
											title: "Setup costs (Once off)",
											description: `Design cost, 
URL setup,Dashboard setup,
Setup & file hosting,
Questionnaire logic`,
											price: "R 11,500",
										},
										{
											title: "Monthly Operational Costs",
											description: `Hosting x 10 trackable QR codes, 
Cloud Services,
Security Services,
Tech Support SLA`,
											price: "R 6,820",
										},
										{
											title: "Additional",
											description: `Per additional trackable QR code: `,
											price: "R 682",
										},
									],
									additional: `Monthly thresholds are applicable and service costs are based on a mimimum level of useage. 

- Hosting (10,000 interactions per month) 
- Cloud Storage: SSD storage size: 80GB
- Cloud backups & snapshots
- A system-level backup is taken once a month, and each backup is retained for 4 weeks.

Communications thresholds:
- SMS's (not included)
- Email's (not included)`,
									terms: ``,

									expiry: moment(
										"10/05/2023",
										"DD/MM/YYYY",
									).format("DD MMM YYYY"),
								}}
							/>
						}
					/>

					<Route
						path={c.hydraplayactivation}
						exact
						element={
							<Proposals
								data={[
									{
										body: [
											{
												type: "h1",
												text: `
Once a customer has used the skin diagnostics web application to find the right Elizabeth Arden products for their skin concerns `,
											},
										],
									},
									{
										body: [
											{
												type: "p",
												text: `
Once a customer has used the skin diagnostics web application to find the right Elizabeth Arden products for their skin concerns and needs, the next step would be to download the free Elizabeth Arden skincare app. This app would allow users to track their skincare progress and participate in a 7-day skin transformation challenge.

The skincare app would enable users to track their daily skincare routine and monitor the improvements in their skin over time. This could include tracking factors such as hydration, texture, and radiance. Users could also receive personalized skincare tips and advice based on their progress and needs.

Additionally, the app could offer a 7-day skin transformation challenge, where users follow a prescribed skincare routine using the recommended Elizabeth Arden products for seven days to achieve a visible improvement in their skin. This could include daily reminders to use the products, as well as helpful tips and guidance on how to maximize the benefits of the products.

By offering the skincare app and 7-day transformation challenge, Elizabeth Arden can provide an additional layer of value to their customers beyond just product recommendations. The app can help to build brand loyalty and engagement by providing users with a comprehensive and personalized skincare experience.`,
											},
										],
									},
								]}
								costEstimate={{
									clientDetails: "Elizabeth Arden",
									title: "Skin tools Web App",
									description: `To accurately determine the cost of the application, we need to create a flow diagram and wireframe to design the platform. This will enable us to determine the development and time required to complete the project, as well as the scale and detail of backend services. We must take into account important factors such as the amount of data to be streamed, the number of users the app will have, and the data being stored and distributed.

            Some aspects of the application involve development-only costs, while others require minimal development yet carry a monthly running cost. In addition, we have drafted a mock-up of the app to provide a visual representation of the proposed user interface. Of course, the user interface will be designed to reflect the brand's product colors.`,

									lines: [
										{
											title: "Setup costs (Once off)",
											description: `Design cost, 
URL setup,Dashboard setup,
Setup & file hosting,
Questionnaire logic`,
											price: "R 11,500",
										},
										{
											title: "Monthly Operational Costs",
											description: `Hosting x 10 trackable QR codes, 
Cloud Services,
Security Services,
Tech Support SLA`,
											price: "R 6,820",
										},
										{
											title: "Additional",
											description: `Per additional trackable QR code: `,
											price: "R 682",
										},
									],
									additional: `Monthly thresholds are applicable and service costs are based on a mimimum level of useage. 

- Hosting (10,000 interactions per month) 
- Cloud Storage: SSD storage size: 80GB
- Cloud backups & snapshots
- A system-level backup is taken once a month, and each backup is retained for 4 weeks.

Communications thresholds:
- SMS's (not included)
- Email's (not included)`,
									terms: ``,

									expiry: moment(
										"10/05/2023",
										"DD/MM/YYYY",
									).format("DD MMM YYYY"),
								}}
							/>
						}
					/>

					<Route path='*' element={<Navigate to='/proposal/' />} />
				</Routes>
			</Router>
		);
	}
}

export default withPreventZoom(App);
