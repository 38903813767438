import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "./APP_VARS";

import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page4 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		// const defaultOptions = {
		// 	loop: true,
		// 	autoplay: true,
		// 	animationData: animationData,
		// 	rendererSettings: {
		// 		preserveAspectRatio: "xMidYMid slice",
		// 	},
		// };
		let mw = 1200;
		let h = this.props.screenHeight;
		let w = this.props.screenWidth > mw ? mw : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		let fontS = {
			fontSize: 20,
			color: APP_COLOURS.TEXTCOLOR,
			fontFamily: "Montserrat",
			textAlign: "justify",
			fontWeight: "300",
		};

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							width: w,
							// height: h,
							height: "auto",
							padding: 20,
							paddingTop: "10%",
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Fade>
							<div
							// className={
							// 	this.state.inView ? `reveal` : `fadeOut`
							// }
							>
								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										height: h * 0.33,
										width: window.innerWidth * 0.8,
										maxWidth: mw,
									}}>
									<View
										style={{
											flex: 1,
											padding: 20,
										}}>
										<Image
											source={require("./assets/pluginContent.png")}
											style={{
												width: "90%",
												height: h * 0.33,
												resizeMode: "contain",
												// right: "-10%",
												bottom: 0,
											}}
										/>
									</View>
									<View
										style={{
											flex: 1,

											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													fontSize: 50,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={600}>
													Creating Content that
													engages
												</Font>
											</Text>

											<Text
												style={{
													fontSize: 22,

													marginBottom: 5,
													flexDirection: "row",
													opacity: 0.4,
													color: "#000",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													Captivating Audiences with
													Beauty and Elegance
												</Font>
											</Text>
										</View>
									</View>
								</View>

								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h / 2,
										width: window.innerWidth * 0.8,
										maxWidth: mw,
										marginTop: 30,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										{[
											{
												title: "Product showcases and tutorials",
												points: [
													"Feature captivating product demonstrations and makeup tutorials that highlight the unique features, benefits, and applications of Revlon & Elizabeth Arden's offerings.",
													"Collaborate with makeup artists and beauty influencers to create a variety of looks, from everyday elegance to glamorous evening styles.",
												],
											},
											{
												title: "Behind-the-scenes insights",
												points: [
													"Share the creative process behind product development and the journey from concept to creation.",
													"Introduce key team members and showcase their expertise, passion, and dedication to the brands.",
												],
											},
											{
												title: "Brand heritage and storytelling",
												points: [
													"Dive into the rich histories of Revlon & Elizabeth Arden, exploring their origins, brand values, and milestones.",
													"Celebrate the brands' legacies by sharing inspiring stories of their founders and pivotal moments that have shaped the beauty industry.",
												],
											},
											{
												title: "Customer success stories and testimonials",
												points: [
													"Feature real customer stories, showcasing their transformations and experiences with Revlon & Elizabeth Arden products.",
													"Encourage user-generated content by inviting customers to share their beauty journeys on social media, using dedicated hashtags.",
												],
											},
											{
												title: "Seasonal campaigns and collaborations",
												points: [
													"Launch thematic campaigns and limited-edition collections that align with seasonal trends, holidays, and cultural events.",
													"Collaborate with designers, artists, and other creative partners to develop exclusive product lines, packaging designs, and promotional content.",
												],
											},
											{
												title: "Educational and expert advice",
												points: [
													"Provide beauty tips, skincare advice, and industry insights from renowned experts, estheticians, and makeup artists.",
													"Address common beauty concerns and answer frequently asked questions through engaging blog posts, video series, and live Q&A sessions.",
												],
											},
										].map((d) => (
											<>
												<Text
													style={[
														fontS,
														{
															marginBottom: 15,
															marginTop: 20,
															fontWeight: "500",
														},
													]}>
													{d.title}
												</Text>
												{d.points.map((db) => (
													<Text
														style={[
															{
																fontSize: 19,
																color: APP_COLOURS.TEXTCOLOR,
																marginBottom: 5,
																flexDirection:
																	"row",
															},
															fontS,
														]}>
														{db}
													</Text>
												))}
											</>
										))}
									</View>
								</View>
							</div>
						</Fade>
					</View>

					{/* <PageFooter /> */}
				</View>
			</div>
		);
	}
}
