import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/76961-weather-alert-notification.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page16 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = this.props.screenHeight;
		let w = this.props.screenWidth > 1000 ? 1000 : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		return (
			<div
				style={{
					width: w,
					minHeight: h,
					height: "auto",
					justifyContent: "center",
					alignItems: "center",
					paddingTop: "5%",
				}}>
				<View
					style={{
						position: "absolute",
						bottom: 0,
						left: 0,
						zIndex: 888,
					}}>
					<Image
						source={require("./assets/dots.png")}
						style={{
							width: 500,
							height: 500,
							resizeMode: "contain",
						}}
					/>
				</View>
				<View
					style={{
						position: "absolute",
						top: 0,
						right: 0,
						zIndex: 989,
					}}>
					<Image
						source={require("./assets/dots.png")}
						style={{
							width: 500,
							height: 500,
							resizeMode: "contain",
						}}
					/>
				</View>
				<View
					style={{
						position: "absolute",
						bottom: 60,
						right: 0,
						zIndex: 899,
					}}>
					<Image
						source={require("./assets/agreeMent.png")}
						style={{
							width: 200 * (368.94 / 255.42),
							height: 200,
							resizeMode: "contain",
						}}
					/>
				</View>
				<View
					style={{
						width: "100%",
						backgroundColor: "#1C2B46",
						height: 65,
						position: "absolute",
						bottom: 0,
						left: 0,
						justifyContent: "center",
						alignItems: "center",
						zIndex: 999,
					}}>
					<View
						style={{
							width: "100%",
							flexDirection: "row",
							maxWidth: 1200,
							justifyContent: "space-between",
						}}>
						<Image
							source={require("./assets/services.png")}
							style={{
								width: 300,
								height: 35,
								resizeMode: "contain",
							}}
						/>
						<Image
							source={require("./assets/infod.png")}
							style={{
								width: 220,
								height: 35,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						paddingBottom: "5%",
					}}>
					<View
						style={{
							width: w,
							maxWidth: 1200,
							padding: 20,
							// height: h,
							height: "auto",

							zIndex: 1,
						}}>
						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								// height: h * 0.2,
								width: w,
								// marginBottom: "40%",
							}}>
							<View
								style={{
									flex: 1,
									// padding: 20,
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<View
									style={{
										flex: 1,
									}}>
									<Text
										style={{
											fontSize: 35,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={700}>
											REVLON & Elizabeth Arden
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 35,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={500}>
											Digital Support Retainer
										</Font>
									</Text>
								</View>
							</View>
						</View>

						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								// height: h / 2,
								width: w,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										flexDirection: "row",
									}}></View>
								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 25,
										flexDirection: "row",
									}}>
									<Font family='Montserrat' weight={300}>
										This proposal outlines a complete suite
										of digital services designed to maintain
										and optimize your website's performance,
										enhance user experience, and ensure
										seamless product listing and management.
										Our team will provide end-to-end
										support, including website updates,
										product listing and maintenance,
										retailer link management, technical
										support, Google Analytics reporting, and
										general digital and technical advisory
										services.
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 15,
									}}>
									<Font family='Montserrat' weight={500}>
										Scope of work:
									</Font>
								</Text>

								{[
									{
										title: "1. Local Site Development & Implementation",
										desc: "Develop and implement sites for Revlon.co.za, Crème of Nature, Elizabeth Arden.co.za, and Mitchum.co.za. Tasks include uploading and aligning the South African product catalog, ensuring user journey experience, and monthly or as-required site maintenance.",
									},
									{
										title: "2. Creation and Maintenance of Master Data Listing Document",
										desc: "Create and maintain a master data listing document with all relevant fields for Revlon cosmetics, Personal Care (Hair, Mitchum, Body Sprays), Fragrances, and Elizabeth Arden. Keep the document and asset library updated with new product developments (NPDs) and delistings.",
									},
									{
										title: "3. Updating of online product listings",
										desc: "Regularly review and update product listings on popular e-commerce websites, such as Takealot, Foschini, Woolworths, Dis-Chem, Truworths, Edgars, and others. Ensure accurate and up-to-date product information, descriptions, images, and pricing, while maintaining brand consistency and alignment with brand guidelines. Monitor stock availability and liaise with retailers to address any discrepancies or issues in a timely manner.",
									},
									{
										title: "4. Updating consumer complaints to global portal",
										desc: "Diligently track, record, and escalate customer complaints received via various channels, such as email, phone, or social media, to the global portal. Ensure that all relevant information, including complaint details, product information, and customer contact information, is accurately documented for efficient resolution. Monitor the progress of complaints and follow up with the global team when necessary, while keeping customers informed of the status of their concerns.",
									},
									{
										title: "5. Ad Hoc Requirements",
										desc: "Attend meetings related to the scope of work, and offer ad hoc technical and listing support as needed.",
									},
								].map((d, i) => (
									<Text
										style={{
											fontSize: 15,
											color: APP_COLOURS.TEXTCOLOR,

											flexDirection: "row",
											marginBottom: 15,
										}}>
										<Font family='Montserrat' weight={500}>
											{d.title}
										</Font>
										<Text
											style={{
												fontSize: 12,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												{d.desc}
											</Font>
										</Text>
									</Text>
								))}

								<Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 15,
									}}>
									<Font family='Montserrat' weight={500}>
										Deliverables:
									</Font>
								</Text>

								{[
									{
										title: "Task 1: Home Page Updates",
										desc: "Step 1: Collaborate with other agencies to create a calendar for banners and carousels. Step 2: Update banners and carousels based on the calendar. Step 3: Participate in monthly 30-minute meetings to review the calendar and maintain alignment. Step 4: Handle ad hoc home page updates for urgent changes or last-minute campaigns. Step 5: Brief assets and ensure their timely preparation according to the process defined in ClickUp. Step 6: Collaborate with the US development team on new initiatives through Jira.",
									},
									{
										title: "Task 2: VTO Maintenance",
										desc: "Step 1: Enable or disable products as needed. Step 2: Provide status updates and reporting. Step 3: Handle ad hoc requests within reason.",
									},
									{
										title: "Task 3: Product Listing and Maintenance",
										desc: "Step 1: List new product developments (NPDs). Step 2: Update products with any changes. Step 3: Remove discontinued products from listings.",
									},
									{
										title: "Task 4: Retailer Links Management",
										desc: "Step 1: Update links if they change. Step 2: Perform monthly link audits (randomly check 10 products every month). Step 3: Obtain links for NPDs or new listings.",
									},
									{
										title: "Task 5: Technical Support",
										desc: "Step 1: Publish website content. Step 2: Create devrequest tickets. Step 3: Report and escalate bugs. Step 4: Assist with website issues as needed, whether by resolving them internally or escalating to the US development team.",
									},
									{
										title: "Task 6: Google Analytics Reporting",
										desc: "Step 1: Provide reports on a monthly basis. Step 2: Include white-label presentations. Step 3: Include VTO activation reports.",
									},
									{
										title: "Task 7: Administration",
										desc: "Step 1: Manage communications, emails, and other administrative tasks. Step 2: Submit timesheets.",
									},
									{
										title: "Task 8: General Digital & Technical Support Advisory",
										desc: "Step 1: Offer consultation and advice when requested, within reason.",
									},
								].map((d, i) => (
									<Text
										style={{
											fontSize: 15,
											color: APP_COLOURS.TEXTCOLOR,

											flexDirection: "row",
											marginBottom: 15,
										}}>
										<Font family='Montserrat' weight={500}>
											{d.title}
										</Font>
										<Text
											style={{
												fontSize: 12,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												{d.desc}
											</Font>
										</Text>
									</Text>
								))}

								<Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 15,
									}}>
									<Font family='Montserrat' weight={500}>
										Hours and Response Times:
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={300}>
										Proposed costs include a dedicated
										content manager to Revlon and Elizabeth
										Arden, available for 16 hours a day,
										this is limited to content management on
										the platforms (responding to content
										changes). The content manager will be
										available to the Revlon & Elizabeth
										Arden team for queries and updates
										during working hours between
										(08:30-17:00). We guarantee an average
										response time of 1 hour during office
										hours. After-hours content management
										will not be charged additionally however
										do come with deleys in turn-around time
										on a case-by-case basis.
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={500}>
										Deliverables:
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
									}}>
									<Font family='Montserrat' weight={300}>
										Revlon.co.za Site Completion, Creation &
										Completion of Master data Listing
										document, Crème of Nature, Mitchum Site
										Update, Revlon & Elizabeth Arden site
										maintenance, Deliverable Monthly Review
										Meeting
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={500}>
										Time Allocation:
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 25,
										flexDirection: "row",
									}}>
									<Font family='Montserrat' weight={300}>
										Monthly Retainer, 100 Hours per month,
										10 Additional hours bankable with a
										3-month expiry (at no cost), and
										Quarterly review of cost & alignment.
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={500}>
										Period
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 25,
										flexDirection: "row",
									}}>
									<Font family='Montserrat' weight={300}>
										Effective 1st May 2023 – 30th April
										2024.
									</Font>
								</Text>

								{/* <Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={500}>
										Resource Inclusion:
									</Font>
								</Text>

								{[
									{ title: "Content manager" },
									{ title: "Jira" },
									{ title: "Clickup" },
								].map((d) => (
									<Text
										style={{
											fontSize: 15,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 2,
											flexDirection: "row",
											marginTop: 2,
										}}>
										<Font family='Montserrat' weight={300}>
											{d.title}
										</Font>
									</Text>
								))} */}

								<View
									style={{
										flexDirection: "row",
										marginTop: 20,
										alignItems: "center",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontSize: 19,
											color: APP_COLOURS.TEXTCOLOR,

											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={700}>
											Revlon:{" "}
										</Font>
										<Text
											style={{
												fontSize: 15,
												color: APP_COLOURS.TEXTCOLOR,

												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Content Management:
											</Font>
										</Text>
									</Text>

									<Text
										style={{
											fontSize: 22,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 25,
											flexDirection: "row",
											marginTop: 25,
										}}>
										<Font family='Montserrat' weight={700}>
											R 12,950
										</Font>
									</Text>
								</View>

								<View
									style={{
										flexDirection: "row",
										marginTop: 20,
										alignItems: "center",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontSize: 19,
											color: APP_COLOURS.TEXTCOLOR,

											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={700}>
											Elizabeth Arden:{" "}
										</Font>
										<Text
											style={{
												fontSize: 15,
												color: APP_COLOURS.TEXTCOLOR,

												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Content Management:
											</Font>
										</Text>
									</Text>

									<Text
										style={{
											fontSize: 22,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 25,
											flexDirection: "row",
											marginTop: 25,
										}}>
										<Font family='Montserrat' weight={700}>
											R 5,550
										</Font>
									</Text>
								</View>

								<View
									style={{
										width: "100%",
										borderWidth: 0.5,
										borderColor: APP_COLOURS.TEXTCOLOR,
									}}
								/>
							</View>
						</View>

						<Text
							style={{
								fontSize: 13,
								color: APP_COLOURS.TEXTCOLOR,
								marginBottom: 2,
								flexDirection: "row",
								marginTop: 70,
							}}>
							<Font family='Montserrat' weight={300}>
								* All quoted amounts are monthly recurring and
								exclusive of VAT unless stated otherwise.
							</Font>
						</Text>
					</View>
				</View>
			</div>
		);
	}
}
